(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('SmootherMultiplierDialogController', SmootherMultiplierDialogController);

    SmootherMultiplierDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'SmootherMultiplier', 'ModelSymbol', 'Timeseries'];

    function SmootherMultiplierDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, SmootherMultiplier, ModelSymbol, Timeseries) {
        var vm = this;

        vm.smootherMultiplier = entity;
        vm.clear = clear;
        vm.save = save;
        vm.modelsymbols = ModelSymbol.query({filter: 'smoothermultiplier-is-null'});
        $q.all([vm.smootherMultiplier.$promise, vm.modelsymbols.$promise]).then(function() {
            if (!vm.smootherMultiplier.modelSymbolId) {
                return $q.reject();
            }
            return ModelSymbol.get({id : vm.smootherMultiplier.modelSymbolId}).$promise;
        }).then(function(modelSymbol) {
            vm.modelsymbols.push(modelSymbol);
        });
        vm.timeseriess = Timeseries.query({filter: 'smoothermultiplier-is-null'});
        $q.all([vm.smootherMultiplier.$promise, vm.timeseriess.$promise]).then(function() {
            if (!vm.smootherMultiplier.timeseriesId) {
                return $q.reject();
            }
            return Timeseries.get({id : vm.smootherMultiplier.timeseriesId}).$promise;
        }).then(function(timeseries) {
            vm.timeseries.push(timeseries);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.smootherMultiplier.id !== null) {
                SmootherMultiplier.update(vm.smootherMultiplier, onSaveSuccess, onSaveError);
            } else {
                SmootherMultiplier.save(vm.smootherMultiplier, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('portalApp:smootherMultiplierUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
