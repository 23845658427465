(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('RoundDetailController', RoundDetailController);

    RoundDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Round', 'Product', 'Country', 'Model'];

    function RoundDetailController($scope, $rootScope, $stateParams, entity, Round, Product, Country, Model) {
        var vm = this;

        vm.round = entity;

        var unsubscribe = $rootScope.$on('portalApp:roundUpdate', function(event, result) {
            vm.round = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
