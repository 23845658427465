(function () {
    'use strict';

    angular
        .module('portalApp')
        .provider('chartConfig', chartConfig);

    // configuration
    chartConfig.$inject = ['chartConstants'];

    function chartConfig(chartConstants) {
        this.timeseriesChartDefaultDateFormat = 'YYFP';
        this.timeseriesChartDefaultFrequency = 4; //quarterly
        this.timeseriesChartDefaultShadowStyle = {
            color: '#aea',
            opacity: 0.25,
            borderWidth: 0
        };
        this.timeseriesChartDefaultObject = {
            // title: {
            //     //Title text is to be filled in the app
            //     text: '',
            //     //Configure chart title font
            //     font: {
            //         opacity: 1,
            //         fontFamily: "Helvetica",
            //         fontWeight: 'lighter',
            //         color: "#46b44f",
            //         size: '16px'
            //     }
            // },
            //Configure Primary X Axis
            primaryXAxis: {
                font: {
                    size: "12px",
                    fontFamily: "Helvetica",
                    color: "#1e2834"
                },
                visible: true, // to be changed for empty charts
                title: {visible: false, text: 'Date'},
                axisLine: {visible: true},
                majorGridLines: {visible: true},
                majorTickLines: {visible: true},
                rangePadding: 'none',
                valueType: 'category',
                labelPlacement: 'onticks',
                labelIntersectAction: 'hide',
                isIndexed: false,
                stripLine: [] //range shadowing is empty by default
            },
            //Configure Primary Y Axis
            primaryYAxis: {
                font: {
                    size: "12px",
                    fontFamily: "Helvetica",
                    color: "#1e2834"
                },
                title: {
                    visible: false,
                    text: 'Yield (%, annually compounded)',
                    font: {
                        size: "12px",
                        fontFamily: "Helvetica",
                        color: "#1e2834"
                    }
                },
                visible: true, // to be changed for empty charts
                axisLine: {visible: true},
                opacity: 0.5,
                majorGridLines: {visible: true}//, rangePadding: 'additional'
            },
            //Configure Common Properties for all the series
            commonSeriesOptions: {
                enableAnimation: false,
                opacity: 0.75,
                border: {
                    width: 2
                },
                marker: {
                    border: {
                        width: 0
                    }
                },
                highlightSettings: {
                    enable: true,
                    mode: 'series',
                    opacity: 1
                }
            },
            //Series are to be filled in the app
            series: [],
            //Configure Zooming
            zooming: {
                enable: false,
                enableMouseWheel: false,
                enableScrollbar: true,
                type: 'x,y'
            },
            //Configure Trackball
            crosshair: {
                visible: true,
                type: 'trackball'
            },
            //OnLoad event handler
            // load: onChartLoaded,
            //Chart appearance and other properties
            chartArea: {
                border: {
                    opacity: 1
                }
            },
            border: {
                width: 0,
                color: "#5250A1"
            },
            canResize: true,
            isResponsive: true,
            legend: {
                visible: true,
                shape: 'seriesType',
                font: {
                    size: "12px",
                    fontFamily: "Helvetica",
                    color: "#1e2834"
                },
                toggleSeriesVisibility: false
            },
            size: {
                height: '450'//,width: '300'
            },
            annotations: [{
                visible: false, // to be changed for empty charts
                content: "watermark",
                opacity: 0.2,
                region: "series"
            }],
            //customize the trackball tooltip
            trackToolTip: onTooltipShown
        };

        this.timeseriesChartDefaultLineStyles = {
            predefined: [ //todo: only seven lines are predefined so far, will see if more is needed
                {
                    width: 3,
                    color: chartConstants.lineColorSet[0],
                    dashSetting: [1, 0],
                    markerShape: 'Circle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: chartConstants.lineColorSet[1],
                    dashSetting: [1, 0],
                    markerShape: 'Circle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: chartConstants.lineColorSet[2],
                    dashSetting: [1, 0],
                    markerShape: 'Circle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: chartConstants.lineColorSet[3],
                    dashSetting: [1, 0],
                    markerShape: 'Circle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: chartConstants.lineColorSet[4],
                    dashSetting: [1, 0],
                    markerShape: 'Circle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: chartConstants.lineColorSet[5],
                    dashSetting: [1, 0],
                    markerShape: 'Circle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: chartConstants.lineColorSet[6],
                    dashSetting: [1, 0],
                    markerShape: 'Circle',
                    markerSize: 2
                }
            ],
            new: {
                width: 3,
                color: null, //will be picked automatically
                dashSetting: [1, 0],
                markerShape: 'Circle',
                markerSize: 2
            }
        };

        this.$get = function () {
            return {
                timeseriesChartDefaults: {
                    chartObject: this.timeseriesChartDefaultObject,
                    lineStyles: this.timeseriesChartDefaultLineStyles,
                    dateFormat: this.timeseriesChartDefaultDateFormat,
                    frequency: this.timeseriesChartDefaultFrequency,
                    shadowStyle: this.timeseriesChartDefaultShadowStyle
                }
            };
        };

        function onTooltipShown(sender) {
            var dataPoint = sender.data.Series.dataPoint[sender.data.pointIndex];
            if (angular.isDefined(dataPoint.low)) {
                sender.data.currentText = "High : " + dataPoint.high.toPrecision(4) +
                    " <br/> " + "Low : " + dataPoint.low.toPrecision(4);
            } else {
                //reformat Y number up to 4th precision point
                sender.data.currentText = dataPoint.x + " : " + dataPoint.y.toPrecision(4);
            }
        }
    }
})();
