(function () {
    'use strict';

    angular
        .module('portalApp')
        .provider('fanChartConfig', fanChartConfig);

    // configuration
    fanChartConfig.$inject = ['fanChartConstants'];

    function fanChartConfig(fanChartConstants) {
        var fanChartDefaultDateFormat = 'Mmm YYYY';

        this.fanChartDefaultDateFormat = fanChartDefaultDateFormat;
        this.fanChartDefaultFrequency = 12; //monthly
        this.fanChartDefaultShadowStyle = {
            color: '#aea',
            opacity: 0.25,
            borderWidth: 0
        };
        this.fanChartDefaultObject = {
            title: {
                //Title text is to be filled in the app
                text: '',
                //Configure chart title font
                font: {
                    opacity: 1,
                    fontFamily: "Helvetica",
                    fontWeight: 'lighter',
                    color: "#46b44f",
                    size: '16px'
                }
            },
            //Configure Primary X Axis
            primaryXAxis: {
                font: {
                    size: "13px",
                    fontFamily: "Helvetica",
                    color: "#777"
                },
                visible: true, // to be changed for empty charts
                title: {visible: false, text: 'Period'},
                axisLine: {visible: true},
                majorGridLines: {visible: true},
                majorTickLines: {visible: true},
                rangePadding: 'none',
                valueType: 'category',
                labelPlacement: 'onticks',
                // labelIntersectAction: 'rotate45',
                labelRotation: -45,
                isIndexed: false,
                stripLine: [] //range shadowing is empty by default
            },
            //Configure Primary Y Axis
            primaryYAxis: {
                font: {
                    size: "13px",
                    fontFamily: "Helvetica",
                    color: "#777"
                },
                title: {
                    visible: false,
                    text: 'Value',
                    font: {
                        size: "13px",
                        fontFamily: "Helvetica",
                        color: "#777"
                    }
                },
                visible: true, // to be changed for empty charts
                axisLine: {visible: true},
                opacity: 0.5,
                majorGridLines: {visible: true}//, rangePadding: 'additional'
            },
            //Configure Common Properties for all the series
            commonSeriesOptions: {
                enableAnimation: true,
                opacity: 0.75,
                border: {
                    width: 2
                },
                marker: {
                    border: {
                        width: 0
                    }
                },
                highlightSettings: {
                    enable: true,
                    mode: 'series',
                    opacity: 1,
                    pattern: 'diagonalforward'
                },
                tooltip: {
                    font: {
                        color: '#000'
                    }
                }
            },
            //Series are to be filled in the app
            series: [],
            //Configure Zooming
            zooming: {
                enable: false,
                enableMouseWheel: false,
                enableScrollbar: true,
                type: 'x,y'
            },
            //Configure Trackball
            crosshair: {
                visible: true,
                type: 'trackball'
            },
            //OnLoad event handler
            // load: onChartLoaded,
            //Chart appearance and other properties
            chartArea: {
                border: {
                    opacity: 1
                }
            },
            border: {
                width: 0,
                color: "#5250A1"
            },
            canResize: true,
            isResponsive: true,
            legend: {
                visible: true,
                shape: 'seriesType'
            },
            size: {
                height: '400'//,width: '300'
            },
            annotations: [{
                visible: false, // to be changed for empty charts
                content: "watermark",
                opacity: 0.2,
                region: "series"
            }],
            //customize the trackball tooltip
            trackToolTip: onTooltipShown
        };

        this.fanChartDefaultLineStyles = {
            predefined: [
                {
                    width: 3,
                    color: fanChartConstants.colorSet.history,
                    dashSetting: [1, 0],
                    markerShape: 'Circle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: fanChartConstants.colorSet.fcast,
                    dashSetting: [3, 6],
                    markerShape: 'Circle',
                    markerSize: 2
                }
            ],
            new: {
                width: 3,
                color: null, //will be picked automatically
                dashSetting: [1, 0],
                markerShape: 'None',
                markerSize: 0
            }
        };

        this.fanChartDefaultBandStyles = {
            band30: {
                color: fanChartConstants.colorSet.band30
            },
            band60: {
                color: fanChartConstants.colorSet.band60
            },
            band95: {
                color: fanChartConstants.colorSet.band95
            },
            new: function (bandWidth) {
                var newMix = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
                    .exec(fanChartConstants.colorSet.band30);
                bandWidth = bandWidth - 0.3;
                newMix = "#"
                    + (parseInt(newMix[1], 16) * (1 - bandWidth) + bandWidth * 255 | 0)
                        .toString(16)
                    + (parseInt(newMix[2], 16) * (1 - bandWidth) + bandWidth * 255 | 0)
                        .toString(16)
                    + (parseInt(newMix[3], 16) * (1 - bandWidth) + bandWidth * 255 | 0)
                        .toString(16);
                return {color: newMix};
            }
        };

        this.$get = function () {
            return {
                fanChartDefaults: {
                    chartObject: this.fanChartDefaultObject,
                    lineStyles: this.fanChartDefaultLineStyles,
                    bandStyles: this.fanChartDefaultBandStyles,
                    dateFormat: this.fanChartDefaultDateFormat,
                    frequency: this.fanChartDefaultFrequency,
                    shadowStyle: this.fanChartDefaultShadowStyle
                }
            };
        };

        function onTooltipShown(sender) {
            var dataPoint = sender.data.Series.dataPoint[sender.data.pointIndex];
            //reformat Y number up to 4th precision point
            if (angular.isDefined(dataPoint.low)) {
                sender.data.currentText = "High : " + dataPoint.high.toPrecision(4) +
                    " <br/> " + "Low : " + dataPoint.low.toPrecision(4);
                if (sender.data.Series.name !== "95%") {
                    sender.cancel = true;
                }
            } else {
                if (sender.data.currentText.length > 0) {
                    var dateStr = sender.data.currentText.substring(0, fanChartDefaultDateFormat.length);
                    var numStr = sender.data.currentText.substring(fanChartDefaultDateFormat.length + 3);
                    sender.data.currentText = dateStr + " : " + parseFloat(numStr).toPrecision(4);
                }
            }
        }
    }
})();
