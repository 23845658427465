(function() {
    'use strict';
    angular
        .module('portalApp')
        .factory('VerifyApplicationFeature', VerifyApplicationFeature);

    VerifyApplicationFeature.$inject = ['$resource'];

    function VerifyApplicationFeature ($resource) {

        var resourceUrl =  'api/verify-app-feature';

        return $resource(resourceUrl, {}, {
            'verify': {
                method: 'GET',
                isArray: false,
                interceptor: {
                    response: function(response) {
                        // expose response
                        return response;
                    }
                }
            }
        });
    }
})();
