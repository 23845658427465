(function() {
    'use strict';

    angular
        .module('portalApp')
        .config(stickyStateConfig);

    stickyStateConfig.$inject = ['$stickyStateProvider'];

    function stickyStateConfig($stickyStateProvider) {
        //enable debug
        $stickyStateProvider.enableDebug(false);
    }
})();
