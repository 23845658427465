(function() {
    'use strict';

    angular
        .module('portalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('resource', {
            parent: 'entity',
            url: '/resource',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'portalApp.resource.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/resource/resources.html',
                    controller: 'ResourceController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('resource');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('resource-detail', {
            parent: 'entity',
            url: '/resource/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'portalApp.resource.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/resource/resource-detail.html',
                    controller: 'ResourceDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('resource');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Resource', function($stateParams, Resource) {
                    return Resource.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('resource.new', {
            parent: 'resource',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/resource/resource-dialog.html',
                    controller: 'ResourceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                url: null,
                                description: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('resource', null, { reload: true });
                }, function() {
                    $state.go('resource');
                });
            }]
        })
        .state('resource.edit', {
            parent: 'resource',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/resource/resource-dialog.html',
                    controller: 'ResourceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Resource', function(Resource) {
                            return Resource.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('resource', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('resource.delete', {
            parent: 'resource',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/resource/resource-delete-dialog.html',
                    controller: 'ResourceDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Resource', function(Resource) {
                            return Resource.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('resource', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
