(function () {
    'use strict';

    angular
        .module('portalApp')
        .constant('fanChartConstants', {
            'colorSet': {
                history: '#46b44f',
                fcast: '#b5ab56',
                band30: '#394A58',
                band60: '#8B92B5',
                band95: '#cdcfd8'
            }
        });
})();
