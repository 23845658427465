(function () {
    'use strict';

    angular
        .module('portalApp')
        .controller('MainController', MainController);

    MainController.$inject = ['$http', '$state', '$scope', '$window', '$uibModal', '$filter', 'Principal', 'Auth', 'subscribedCountryList',
        'subscribedFPASCountryList', 'subscribedMRMCountryList', 'subscribedCRRCountryList', 'subscribedYCCountryList',
        'riskAndRatingData', 'DataUtils'];

    function MainController($http, $state, $scope, $window, $uibModal, $filter, Principal, Auth, subscribedCountryList,
                            subscribedFPASCountryList, subscribedMRMCountryList,
                            subscribedCRRCountryList, subscribedYCCountryList, riskAndRatingData, DataUtils) {

        var vm = this;
        vm.searchQuery = "";
        vm.$state = $state;
        //exclude external sector from the list of countries for the panel
        vm.subscribedCountryList = subscribedCountryList.filter(function (country) {
            return (country.id !== 73);
        });
        vm.countryRatingRisk = riskAndRatingData;
        vm.limitCountriesTo = 10;
        vm.activeFPASButton = true;
        vm.activeMRMButton = true;
        vm.activeCRRButton = true;
        vm.activeYCButton = true;
        vm.FPASCountryList = viewForecastCountriesButton();
        vm.MRMCountryList = viewMacroRiskCountriesButton();
        vm.CRRCountryList = viewCountryRiskCountriesButton();
        vm.YCCountryList = viewYieldCurveCountriesButton();

        vm.getPropForId = getPropForId;
        vm.onCountrySelected = onCountrySelected;
        vm.getArrowClass = getArrowClass;
        vm.viewAllCountriesButton = viewAllCountriesButton;
        vm.viewForecastCountriesButton = viewForecastCountriesButton;
        vm.viewMacroRiskCountriesButton = viewMacroRiskCountriesButton;
        vm.viewCountryRiskCountriesButton = viewCountryRiskCountriesButton;
        vm.viewYieldCurveCountriesButton = viewYieldCurveCountriesButton;
        vm.showAcceptProtectionPolicy = showAcceptProtectionPolicy;
        vm.verifyProtectionPolicyAcceptance = verifyProtectionPolicyAcceptance;

        vm.viewFPASCountries = viewFPASCountries;
        vm.viewMRMCountries = viewMRMCountries;
        vm.viewCRRCountries = viewCRRCountries;
        vm.viewYCCountries = viewYCCountries;
        vm.submitSearch = submitSearch;
        vm.downloadCSV = downloadCSV;

        var countryPanel = angular.element(document.querySelector('.country-list-panel-outer'));
        var win = angular.element($window);
        win.bind('resize.doResize', onWindowResize);
        $scope.$on("$destroy", function () {
            win.off("resize.doResize"); //remove the handler added earlier
        });
        win.bind('scroll', onWindowScroll);
        $scope.$on("$destroy", function () {
            win.off("scroll"); //remove the handler added earlier
        });


        vm.verifyProtectionPolicyAcceptance();

        function submitSearch() {
            if (vm.searchQuery.trim() == '')
                return;
            var cnt = $filter('orderBy')($filter('filter')(vm.subscribedCountryList, vm.searchQuery), 'name')[0];
            if (angular.isDefined(cnt)) {
                vm.onCountrySelected(cnt.id);
            }
        }

        function onCountrySelected(countryId) {
            // in case of US go to external page
            if (countryId === 73) {
                $state.go('externalSector', {roundId: "latest"});
            } else {
                $state.go('selectedCountry', {countryId: countryId});
            }

        }

        function viewFPASCountries() {

            vm.activeFPASButton = !vm.activeFPASButton;
            viewCountries();
        }

        function viewMRMCountries() {

            vm.activeMRMButton = !vm.activeMRMButton;
            viewCountries();
        }

        function viewCRRCountries() {

            vm.activeCRRButton = !vm.activeCRRButton;
            viewCountries();
        }

        function viewYCCountries() {

            vm.activeYCButton = !vm.activeYCButton;
            viewCountries();
        }

        function viewCountries() {
            vm.subscribedCountryList = [];
            if (vm.activeFPASButton) {
                vm.subscribedCountryList = vm.subscribedCountryList.concat(vm.FPASCountryList);
            }
            if (vm.activeMRMButton) {
                vm.subscribedCountryList = vm.subscribedCountryList.concat(vm.MRMCountryList);
            }
            if (vm.activeCRRButton) {
                vm.subscribedCountryList = vm.subscribedCountryList.concat(vm.CRRCountryList);
            }
            if (vm.activeYCButton) {
                vm.subscribedCountryList = vm.subscribedCountryList.concat(vm.YCCountryList);
            }
            if (vm.subscribedCountryList.length) {
                vm.subscribedCountryList = remove_duplicates_safe(vm.subscribedCountryList);
            } else {
                $state.go('main', {});
            }
            // go to 'main' if selected country is not among filtered list
            if (vm.$state.includes('selectedCountry') && vm.getPropForId(vm.$state.params.countryId, 'code') == null) {
                $state.go('main', {});
            }
        }

        function remove_duplicates_safe(arr) {
            var seen = {};
            var ret_arr = [];
            const is_single_product = (vm.activeFPASButton + vm.activeMRMButton + vm.activeCRRButton + vm.activeYCButton) == 1;
            for (var i = 0; i < arr.length; i++) {
                if (!(arr[i].code in seen)) {
                    ret_arr.push(angular.copy(arr[i]));
                    seen[arr[i].code] = true;
                    if (!is_single_product) {
                        ret_arr[ret_arr.length - 1].latestRoundCreatedDate = null;
                    }
                }
            }
            return ret_arr;
        }

        function viewAllCountriesButton() {
            return subscribedCountryList.filter(function (country) {
                return (country.id !== 73);
            });
        }

        function viewForecastCountriesButton() {
            return subscribedFPASCountryList.filter(function (country) {
                return (country.id !== 73)
            })
        }

        function viewMacroRiskCountriesButton() {
            return subscribedMRMCountryList.filter(function (country) {
                return (country.id !== 73)
            });
        }

        function viewCountryRiskCountriesButton() {
            return subscribedCRRCountryList.filter(function (country) {
                return (country.id !== 73)
            });
        }

        function viewYieldCurveCountriesButton() {
            return subscribedYCCountryList.filter(function (country) {
                return (country.id !== 73)
            });
        }

        function getPropForId(id, propName) {
            if (angular.isUndefined(id) || angular.isUndefined(propName)) {
                return null;
            }
            var countryObj = vm.subscribedCountryList.find(function (c) {
                return (c.id == id);
            });
            if (angular.isUndefined(countryObj)) {
                return null;
            }
            return countryObj[propName];
        }

        function getArrowClass(change) {
            return (change >= 0)
                ? "glyphicon glyphicon-arrow-down text-danger"
                : "glyphicon glyphicon-arrow-up text-success";
        }

        function onWindowResize() {
            countryPanel.height(win.innerHeight() - 70 + win.scrollTop());
            $scope.$apply();
        }

        function onWindowScroll() {
            countryPanel.height(win.innerHeight() - 70 + win.scrollTop());
        }

        function verifyProtectionPolicyAcceptance() {

            $http({
                method: 'GET',
                url: '/api/user_acceptance?code=GDPR-text'
            }).then(function (response) {
                // this is ok
            }, function (response) {
                vm.showAcceptProtectionPolicy();
            });
        }

        function showAcceptProtectionPolicy() {
            $uibModal.open({
                templateUrl: 'app/admin/protection-policy/protection-policy.modal.html',
                controller: 'ProtectionPolicyModalController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });
        }

        function downloadCSV(dir) {
            var i, oneCountry, full_data, filename;
            var linedelimiter = '\n';

            // Sort the data (highest (descending) or lowest (ascending))
            var ordered = vm.countryRatingRisk.sort(function (a, b) {
                return (a.ratingCurrent.value - b.ratingCurrent.value) * ((dir === 'highest') ? 1 : -1)
            });

            // table header
            full_data = ['Country', 'Currency', 'Rating', 'Outlook', 'Macro Risk', '3M Change'];
            full_data += linedelimiter;

            // Data (either top 10 or all)
            for (i = 0; i < vm.countryRatingRisk.length; i++) {
                oneCountry = [
                    '"' + ordered[i].name + '"',
                    ordered[i].currency.code,
                    ordered[i].ratingCurrent.code,
                    (ordered[i].ratingOutlook === 'N/A') ? '' : ordered[i].ratingOutlook,
                    ordered[i].categoryCurrent,
                    (ordered[i].changeValue === null) ? '' : ((ordered[i].changeValue >= 0) ? 'increased' : 'decreased')
                ];
                full_data += oneCountry;
                full_data += linedelimiter;
            }

            // Export
            filename = ['All_Macro_and_Country_Risk_Ratings', '.csv'];
            filename = filename.join('');

            DataUtils.saveFile('csv', full_data, filename);
        }
    }
})();
