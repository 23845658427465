(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('ApplicationFeatureUserGroupMapDialogController', ApplicationFeatureUserGroupMapDialogController);

    ApplicationFeatureUserGroupMapDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ApplicationFeatureUserGroupMap', 'UserGroup', 'ApplicationFeature'];

    function ApplicationFeatureUserGroupMapDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ApplicationFeatureUserGroupMap, UserGroup, ApplicationFeature) {
        var vm = this;

        vm.userGroups = null;
        vm.applicationFeatures = null;
        vm.applicationFeatureUserGroupMap = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        UserGroup.query(function (userGroups) {
            vm.userGroups = userGroups.map(function(obj){return obj.name});
        });

        ApplicationFeature.query(function (applicationFeatures) {
            vm.applicationFeatures = applicationFeatures; //.map(function(obj){return obj.name});
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.applicationFeatureUserGroupMap.id !== null) {
                ApplicationFeatureUserGroupMap.update(vm.applicationFeatureUserGroupMap, onSaveSuccess, onSaveError);
            } else {
                ApplicationFeatureUserGroupMap.save(vm.applicationFeatureUserGroupMap, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('portalApp:applicationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
