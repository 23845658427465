(function () {
    'use strict';
    angular
        .module('portalApp')
        .factory('AppConfig', AppConfig);

    AppConfig.$inject = ['$resource', 'Base64'];

    function AppConfig($resource, Base64) {
        var resourceUrl = 'api/app-config/';

        return function (configGroupName) {
            return $resource(resourceUrl + configGroupName, {}, {
                'getCredentials': {
                    method: 'GET',
                    transformResponse: function (data) {
                        return {data: Base64.decode(data)};
                    }
                }
            })
        };
    }
})();
