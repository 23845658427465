(function() {
    'use strict';
    angular
        .module('portalApp')
        .factory('Resource', Resource);

    Resource.$inject = ['$resource'];

    function Resource ($resource) {
        var resourceUrl =  'api/resources/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'download': {
                method: 'GET',
                responseType: "arraybuffer",
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            }
        });
    }
})();
