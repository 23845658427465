(function() {
    'use strict';

    angular
        .module('portalApp')
        .factory('RevisionSearch', RevisionSearch);

    RevisionSearch.$inject = ['$resource'];

    function RevisionSearch($resource) {
        var resourceUrl =  'api/_search/revisions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
