(function() {
    'use strict';

    angular
        .module('portalApp')
        .factory('ModelSymbolSearch', ModelSymbolSearch);

    ModelSymbolSearch.$inject = ['$resource'];

    function ModelSymbolSearch($resource) {
        var resourceUrl =  'api/_search/model-symbols/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
