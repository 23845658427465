(function() {
    'use strict';

    angular
        .module('portalApp')
        .constant('paginationConstants', {
            'itemsPerPage': 20,
            'userGroupSelectionItemsPerPage': 100
        });
})();
