(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('TranslationController', TranslationController);

    TranslationController.$inject = ['$scope', '$state', 'Translation', 'TranslationSearch'];

    function TranslationController ($scope, $state, Translation, TranslationSearch) {
        var vm = this;

        vm.translations = [];
        vm.search = search;

        loadAll();

        function loadAll() {
            Translation.query(function(result) {
                vm.translations = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            TranslationSearch.query({query: vm.searchQuery}, function(result) {
                vm.translations = result;
            });
        }    }
})();
