(function() {
    'use strict';

    angular
        .module('portalApp')
        .directive('hasSubscription', hasSubscription);

    hasSubscription.$inject = ['VerifySubscription', 'Principal'];

    function hasSubscription(VerifySubscription, Principal) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            var applicationName = attrs.hasSubscription.replace(/\s+/g, '');

            var setVisible = function () {
                    element.removeClass('hidden');
                },
                setHidden = function () {
                    element.addClass('hidden');
                },
                defineVisibility = function () {

                    VerifySubscription.verify({applicationName:applicationName}).$promise
                        .then(function (result) {
                            if (result.data == true) {
                                setVisible();
                            } else {
                                setHidden();
                            }
                        })
                        .catch(function () {
                            setHidden();
                            // alert(result || 'Request failed');
                        });
                };

            if (applicationName.length > 0) {
                setHidden();

                scope.$watch(function() {
                    return Principal.isAuthenticated();
                }, function() {
                    defineVisibility();
                });
            }
        }
    }
})();
