(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('TimeseriesDataDetailController', TimeseriesDataDetailController);

    TimeseriesDataDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'TimeseriesData', 'Timeseries', 'Revision'];

    function TimeseriesDataDetailController($scope, $rootScope, $stateParams, DataUtils, entity, TimeseriesData, Timeseries, Revision) {
        var vm = this;

        vm.timeseriesData = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('portalApp:timeseriesDataUpdate', function(event, result) {
            vm.timeseriesData = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
