(function () {
    'use strict';

    angular
        .module('portalApp')
        .directive('fanChart', fanChart);

    fanChart.$inject = ['$window', 'fanChartConfig', 'TimeseriesUtils', 'DateUtils'];

    function fanChart($window, fanChartConfig, TimeseriesUtils, DateUtils) {
        // Generates chart for one or more timeseries
        // Usage:
        //  <div data-fan-chart chart-data="vm.chartData">
        //  <div data-fan-chart chart-data="vm.chartData" line-style="vm.lineStyle">
        //  <div data-fan-chart chart-data="vm.chartData" date-format="vm.dateFormat" date-frequency="vm.dateFreq">
        //  <div data-fan-chart chart-data="vm.chartData" shadow-range="vm.shadowRange">
        //  <div data-fan-chart chart-data="vm.chartData" min-date="vm.minimumDate">
        //  <div data-fan-chart chart-data="vm.chartData" max-date="vm.maximumDate">
        //  <div data-fan-chart chart-data="vm.chartData" y-label="vm.country.currency.code + ' per USD'">
        // Input structure:
        //  vm.chartData = {
        //      chartId: "someId",
        //      title: "some title",
        //      lineSeries: [
        //          {
        //              name: "Series name"
        //              series: [
        //                  {date: Date, value: Double},
        //                  ...
        //              ]
        //          },
        //          ...
        //      ],
        //      bandSeries: [
        //          {
        //              name: "Band name",
        //              width: 30,
        //              series: [
        //                  {date: Date, upper: Double, lower: Double},
        //                  ...
        //              ]
        //          },
        //          ...
        //      ],
        //  }
        //  vm.lineStyle = [
        //      {
        //          width: 2,
        //          color: '#3734FF',
        //          dashSetting: [<dashWidth>,<emptySpaceWidth>],
        //          markerSize: 2,
        //          markerShape: <one of the following shapes: None, LeftArrow, RightArrow, Circle, Cross, HorizLine,
        //                                                     VertLine, Diamond, Rectangle, Triangle, InvertedTriangle,
        //                                                     Hexagon, Pentagon, Star, Ellipse, Wedge, Trapezoid,
        //                                                     UpArrow, DownArrow>
        //      },
        //      ...
        //  ]
        //  vm.shadowRange = [
        //      {
        //          start: Date,
        //          end: Date,
        //          color: '#373737',
        //          opacity: 0.5
        //      },
        //      ...
        //  ]
        // Creates:
        //  <div data-dd-fan-chart class="fanChart">
        var directive = {
            link: linkFunc,
            restrict: 'A',
            scope: {
                chartData: '<',
                lineStyle: '<',
                dateFormat: '<',
                dateFrequency: '<',
                shadowRange: '<',
                minDate: '<',
                maxDate: '<',
                yLabel: '<'
            }
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            element.addClass('fanChart');
            element.attr('id', scope.chartData.chartId);

            makeChart();

            scope.$watch('chartData', updateChart, true);
            scope.$watch('lineStyle', updateChart, true);
            scope.$watch('dateFormat', updateChart, true);
            scope.$watch('dateFrequency', updateChart, true);
            scope.$watch('shadowRange', updateChart, true);
            scope.$watch('minDate', updateChart, true);
            scope.$watch('maxDate', updateChart, true);
            scope.$watch('yLabel', updateChart, true);
            scope.$watch(parentWidth,
                function (newVal, oldVal) {
                    // update chart after all the other stuff listening to
                    // the change of width of the parent div is done, 1 sec is enough
                    setTimeout(function () {
                        updateChart(newVal, oldVal);
                    }, 1000)
                },
                true);
            //some responsiveness here: when window was resized - recalculate
            // height of the chart based on the parent height
            var win = angular.element($window);
            win.bind("resize", updateChart);
            element.on('$destroy', function () {
                win.unbind("resize", updateChart);
            });

            function parentWidth() {
                return {
                    width: element.parent().width()
                }
            }

            function updateChart(newValue, oldValue) {
                if (newValue === oldValue) {
                    return;
                }
                //unbind this function before removing the chart
                win.unbind("resize", updateChart);
                element.ejChart("destroy");
                element.empty();
                makeChart();
                win.bind("resize", updateChart);
            }

            function makeChart() {
                // console.log('here!');
                //load defaults
                var chartObject = angular.copy(fanChartConfig.fanChartDefaults.chartObject);
                var defaultLineStyles = angular.copy(fanChartConfig.fanChartDefaults.lineStyles);
                var defaultBandStyles = angular.copy(fanChartConfig.fanChartDefaults.bandStyles);
                var defaultDateFormat = angular.copy(fanChartConfig.fanChartDefaults.dateFormat);
                var defaultFrequency = angular.copy(fanChartConfig.fanChartDefaults.frequency);
                var defaultShadowStyle = angular.copy(fanChartConfig.fanChartDefaults.shadowStyle);

                //get chart title from chartData
                // chartObject.title.text = angular.copy(scope.chartData.title);

                //get the label for Y-axis
                var yLabel = angular.copy(scope.yLabel);
                if (!yLabel)
                    yLabel = "";

                //get lines' series from chartData
                var lineData = angular.copy(scope.chartData.lineSeries);

                //get bands' series from chartData
                var bandData = angular.copy(scope.chartData.bandSeries);

                //try getting lines' styles from lineStyle
                var lineStyles = angular.copy(scope.lineStyle);

                //try getting tick labels' date format from dateFormat
                var dateFormat = angular.copy(scope.dateFormat);
                if (!dateFormat)
                    dateFormat = defaultDateFormat;

                //try getting tick labels' date frequency from dateFrequency
                var dateFrequency = angular.copy(scope.dateFrequency);
                if (!dateFrequency)
                    dateFrequency = defaultFrequency;

                //try getting shadow range from shadowRange
                var shadowRange = angular.copy(scope.shadowRange);
                if (!shadowRange)
                    shadowRange = [];

                //try getting minimum date from minDate
                var minDate = angular.copy(scope.minDate);

                //try getting maximum date from maxDate
                var maxDate = angular.copy(scope.maxDate);

                //update chart height based on the height of the parent element
                // console.log(element.parent().height());
                chartObject.size.height = (angular.copy(element.parent().height())).toString();

                //if there was an error don't try to build a chart
                if (lineData === null) {
                    element.append('<p>Unable to plot the chart</p>');
                    return;
                }

                //if there's no line styles provided fill them in with the default line styles
                var ix;
                if (lineData.length > 0 && (!lineStyles || lineStyles.length == 0)) {
                    lineStyles = [];
                    for (ix = 0; ix < Math.min(defaultLineStyles.predefined.length, lineData.length); ix++) {
                        lineStyles.push(defaultLineStyles.predefined[ix]);
                    }
                }

                //if line data is empty show nice watermark
                if (lineData.length == 0) {
                    element.append('<div id="watermark" style="font-size:24px;display:none">Empty</div>');
                    chartObject.annotations[0].visible = true;
                    chartObject.primaryXAxis.visible = false;
                    chartObject.primaryYAxis.visible = false;
                    chartObject.primaryYAxis.title.visible = false;
                    chartObject.series = [{}];
                    chartObject.primaryXAxis.stripLine = [];
                }
                else {
                    chartObject.annotations[0].visible = false;
                    chartObject.primaryXAxis.visible = true;
                    chartObject.primaryYAxis.visible = true;
                    chartObject.primaryYAxis.title.visible = true;
                    chartObject.primaryYAxis.title.text = yLabel;
                    chartObject.series = [];
                    chartObject.primaryXAxis.stripLine = [];
                }
                //cut the range of all chart series
                for (ix = 0; ix < lineData.length; ix++) {
                    if (angular.isDefined(lineData[ix]) && angular.isDefined(lineData[ix].series)) {
                        if (minDate)
                            lineData[ix].series = lineData[ix].series.filter(function (dataPoint) {
                                if (angular.isUndefined(dataPoint))
                                    return false;
                                return new Date(dataPoint.date) >= minDate;
                            });
                        if (maxDate)
                            lineData[ix].series = lineData[ix].series.filter(function (dataPoint) {
                                if (angular.isUndefined(dataPoint))
                                    return false;
                                return new Date(dataPoint.date) <= maxDate;
                            });
                    }
                }
                //cut the range of all bands' series
                for (ix = 0; ix < bandData.length; ix++) {
                    if (angular.isDefined(bandData[ix]) && angular.isDefined(bandData[ix].series)) {
                        if (minDate)
                            bandData[ix].series = bandData[ix].series.filter(function (dataPoint) {
                                if (angular.isUndefined(dataPoint))
                                    return false;
                                return new Date(dataPoint.date) >= minDate;
                            });
                        if (maxDate)
                            bandData[ix].series = bandData[ix].series.filter(function (dataPoint) {
                                if (angular.isUndefined(dataPoint))
                                    return false;
                                return new Date(dataPoint.date) <= maxDate;
                            });
                    }
                }
                //configure shadow ranges
                if (lineData.length > 0)
                    for (ix = 0; ix < shadowRange.length; ix++) {
                        var ixc = angular.copy(ix);
                        var sr = angular.copy(shadowRange[ixc]);
                        sr.visible = true;
                        //todo: lineData[0] is a nasty assumption -- use the series which starts the earliest instead
                        if (shadowRange[ixc].start) {
                            var convertedDate = DateUtils.convertLocalDateToServer(
                                TimeseriesUtils.parseTsDate(
                                    TimeseriesUtils.convertDateToTsDate(shadowRange[ixc].start, dateFrequency, dateFormat), dateFrequency
                                )
                            );
                            sr.start = lineData[0].series.findIndex(function (d) {
                                return d.date == convertedDate;
                            });
                        }
                        else {
                            sr.start = 0;
                        }
                        if (shadowRange[ixc].end) {
                            var convertedDate = DateUtils.convertLocalDateToServer(
                                TimeseriesUtils.parseTsDate(
                                    TimeseriesUtils.convertDateToTsDate(shadowRange[ixc].end, dateFrequency, dateFormat), dateFrequency
                                )
                            );
                            sr.end = lineData[0].series.findIndex(function (d) {
                                return d.date == convertedDate;
                            });
                        }
                        else {
                            if (shadowRange[ixc].start) {
                                sr.end = lineData[0].series.length - 1;
                            }
                            else {
                                sr.end = 0;
                            }
                        }
                        for (var styleProperty in defaultShadowStyle) {
                            if (defaultShadowStyle.hasOwnProperty(styleProperty) && !shadowRange[ixc].hasOwnProperty(styleProperty)) {
                                sr[styleProperty] = defaultShadowStyle[styleProperty];
                            }
                        }
                        chartObject.primaryXAxis.stripLine.push(sr);
                    }
                //minimum and maximum values
                var minValue = Number.MAX_VALUE;
                var maxValue = Number.MIN_VALUE;
                //fill in series of chartObject with the bands' data
                for (ix = 0; ix < bandData.length; ix++) {
                    if (angular.isDefined(bandData[ix]) && angular.isDefined(bandData[ix].series)) {
                        //convert dates to formatted date strings
                        for (jx = 0; jx < bandData[ix].series.length; jx++) {
                            if (bandData[ix].series[jx].lower !== null) {
                                minValue = Math.min(minValue, bandData[ix].series[jx].lower);
                                maxValue = Math.max(maxValue, bandData[ix].series[jx].upper);
                            }
                            bandData[ix].series[jx].dateFormatted = TimeseriesUtils.convertDateToTsDate(bandData[ix].series[jx].date, dateFrequency, dateFormat);
                        }
                        //get the band color
                        var bandColor = null;
                        if (angular.isDefined(defaultBandStyles["band" + bandData[ix].width])) {
                            bandColor = defaultBandStyles["band" + bandData[ix].width].color;
                        }
                        else {
                            bandColor = defaultBandStyles.new(bandData[ix].width / 100);
                        }
                        //add band to the chart structure
                        chartObject.series.push({
                            fill: bandColor,
                            border: {color: 'transparent'},
                            type: 'rangearea',
                            dataSource: bandData[ix].series,
                            name: bandData[ix].name,
                            xName: "dateFormatted",
                            high: "upper",
                            low: "lower"
                        });
                    }
                }
                //fill in series of chartObject with the lines' data
                for (ix = 0; ix < lineData.length; ix++) {
                    if (angular.isDefined(lineData[ix]) && angular.isDefined(lineData[ix].series)) {
                        //convert dates to formatted date strings
                        for (var jx = 0; jx < lineData[ix].series.length; jx++) {
                            var yNum = lineData[ix].series[jx].value;
                            if (yNum !== null) {
                                minValue = Math.min(minValue, yNum);
                                maxValue = Math.max(maxValue, yNum);
                            }
                            lineData[ix].series[jx].dateFormatted =
                                TimeseriesUtils
                                    .convertDateToTsDate(lineData[ix].series[jx].date, dateFrequency, dateFormat);
                        }
                        //use default line style if number of lines is higher the number of predefined lines in config
                        if (lineStyles.length < ix + 1) {
                            lineStyles.push(defaultLineStyles.new);
                        }
                        //add series to the chart structure
                        chartObject.series.push({
                            fill: lineStyles[ix].color,
                            width: lineStyles[ix].width,
                            marker: {
                                visible: false,
                                shape: lineStyles[ix].markerShape,
                                size: {width: lineStyles[ix].markerSize, height: lineStyles[ix].markerSize}
                            },
                            dashArray: lineStyles[ix].dashSetting.toString(),
                            type: 'line',
                            dataSource: lineData[ix].series,
                            name: lineData[ix].name,
                            xName: "dateFormatted",
                            yName: "value"
                        });
                    }
                }
                // adjust Y-axis range to be tight
                var delta = Math.abs(maxValue - minValue);
                if (lineData.length > 0) {
                    chartObject.primaryYAxis.range = {
                        min: +(minValue - 0.1 * delta).toPrecision(3),
                        max: +(maxValue + 0.1 * delta).toPrecision(3)
                    };
                }
                // console.log(chartObject);
                //generate the chart from prepared object
                element.ejChart(angular.copy(chartObject));
            }
        }
    }

})();
