(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('TimeseriesDataDialogController', TimeseriesDataDialogController);

    TimeseriesDataDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'TimeseriesData', 'Timeseries', 'Revision'];

    function TimeseriesDataDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, TimeseriesData, Timeseries, Revision) {
        var vm = this;

        vm.timeseriesData = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.timeseries = Timeseries.query();
        vm.revisions = Revision.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.timeseriesData.id !== null) {
                TimeseriesData.update(vm.timeseriesData, onSaveSuccess, onSaveError);
            } else {
                TimeseriesData.save(vm.timeseriesData, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('portalApp:timeseriesDataUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;
/*
        vm.setObject_values = function ($file, timeseriesData) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        timeseriesData.object_values = base64Data;
                        timeseriesData.object_valuesContentType = $file.type;
                    });
                });
            }
        };
*/
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
