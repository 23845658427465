(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('CurrencyJournalController', CurrencyJournalController);

    CurrencyJournalController.$inject = ['$scope', '$state', 'CurrencyJournal', 'CurrencyJournalSearch'];

    function CurrencyJournalController ($scope, $state, CurrencyJournal, CurrencyJournalSearch) {
        var vm = this;
        
        vm.currencyJournals = [];
        vm.search = search;

        loadAll();

        function loadAll() {
            CurrencyJournal.query(function(result) {
                vm.currencyJournals = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            CurrencyJournalSearch.query({query: vm.searchQuery}, function(result) {
                vm.currencyJournals = result;
            });
        }    }
})();
