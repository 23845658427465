(function() {
    'use strict';

    angular
        .module('portalApp')
        .constant('chartConstants', {
            'lineColorSet': [
                '#46b44f',
                '#356581',
                '#ff8400',
                '#8b4513',
                '#e52b50',
                '#8b92a1',
                '#ffd700'
            ]
        });
})();
