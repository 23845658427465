(function() {
    'use strict';

    angular
        .module('portalApp')
        .factory('CompaniesStatisticsService', CompaniesStatisticsService);

    CompaniesStatisticsService.$inject = ['$resource'];

    function CompaniesStatisticsService ($resource) {
        var service = $resource('api/companies-statistics', {}, {
            'get': {
                method: 'GET',
                isArray: true
            }
        });

        return service;
    }
})();
