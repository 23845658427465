(function() {
    'use strict';
    angular
        .module('portalApp')
        .factory('CountryRiskAndRating', CountryRiskAndRating);

    CountryRiskAndRating.$inject = ['$resource'];

    function CountryRiskAndRating ($resource) {
        var resourceUrl =  'api/risk-and-rating';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
