(function () {
    'use strict';
    angular
        .module('portalApp')
        .controller('CountryNoteController', CountryNoteController);

    CountryNoteController.$inject = ['$filter', '$state', 'countryNotes'];

    function CountryNoteController($filter, $state, countryNotes) {

        var vm = this;

        // vm.country = subscribedCountryList.find(function (c) {
        //     return (c.id == $state.params.countryId);
        // });

        vm.countryNotes = countryNotes;
    }
})();



