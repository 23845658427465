(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('SmootherMultiplierDeleteController',SmootherMultiplierDeleteController);

    SmootherMultiplierDeleteController.$inject = ['$uibModalInstance', 'entity', 'SmootherMultiplier'];

    function SmootherMultiplierDeleteController($uibModalInstance, entity, SmootherMultiplier) {
        var vm = this;

        vm.smootherMultiplier = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            SmootherMultiplier.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
