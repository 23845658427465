(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('ModelDialogController', ModelDialogController);

    ModelDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Model', 'Frequency', 'Round', 'ModelEquation', 'ModelSymbol'];

    function ModelDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Model, Frequency, Round, ModelEquation, ModelSymbol) {
        var vm = this;

        vm.model = entity;
        vm.clear = clear;
        vm.save = save;
        vm.frequencies = Frequency.query();
        vm.rounds = Round.query({filter: 'model-is-null'});
        $q.all([vm.model.$promise, vm.rounds.$promise]).then(function() {
            if (!vm.model.roundId) {
                return $q.reject();
            }
            return Round.get({id : vm.model.roundId}).$promise;
        }).then(function(round) {
            vm.rounds.push(round);
        });
        vm.modelequations = ModelEquation.query();
        vm.modelsymbols = ModelSymbol.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.model.id !== null) {
                Model.update(vm.model, onSaveSuccess, onSaveError);
            } else {
                Model.save(vm.model, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('portalApp:modelUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
