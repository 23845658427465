(function() {
    'use strict';

    angular
        .module('portalApp')
        .factory('ApplicationFeatureSearch', ApplicationFeatureSearch);

    ApplicationFeatureSearch.$inject = ['$resource'];

    function ApplicationFeatureSearch($resource) {
        var resourceUrl =  'api/_search/application-features/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
