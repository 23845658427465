(function () {
    'use strict';

    angular
        .module('portalApp')
        .controller('FooterController', FooterController);

    FooterController.$inject = ['$state', 'FooterService', '$window', 'AppConfig'];

    function FooterController($state, FooterService, $window, AppConfig) {
        var vm = this;

        vm.$state = $state;

        vm.applicationVersion = "";
        vm.newsDbLink = newsDbLink;

        FooterService.get().$promise.then(function (response) {
            vm.applicationVersion = response.applicationVersion;
        });

        function newsDbLink() {
            AppConfig('db-news')
                .getCredentials()
                .$promise
                .then(function (response) {
                    $window.open("https://" + response.data + "@newsdb.ogresearch.com", '_blank');
                });
        }
    }
})();
