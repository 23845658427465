(function() {
    'use strict';

    angular
        .module('portalApp')
        .factory('StatisticsService', StatisticsService);

    StatisticsService.$inject = ['$resource'];

    function StatisticsService ($resource) {
        var service = $resource('api/users-statistics', {}, {
            'get': {
                method: 'GET',
                isArray: true
            }
        });

        return service;
    }
})();
