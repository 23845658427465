(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('FrequencyDetailController', FrequencyDetailController);

    FrequencyDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Frequency'];

    function FrequencyDetailController($scope, $rootScope, $stateParams, entity, Frequency) {
        var vm = this;

        vm.frequency = entity;

        var unsubscribe = $rootScope.$on('portalApp:frequencyUpdate', function(event, result) {
            vm.frequency = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
