(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('ApplicationFeatureEntityMapController', ApplicationFeatureEntityMapController);

    ApplicationFeatureEntityMapController.$inject = ['$scope', '$state', 'ApplicationFeatureEntityMap', 'ApplicationFeatureEntityMapSearch'];

    function ApplicationFeatureEntityMapController ($scope, $state, ApplicationFeatureEntityMap, ApplicationFeatureEntityMapSearch) {
        var vm = this;

        vm.applications = [];
        vm.search = search;

        loadAll();

        function loadAll() {
            ApplicationFeatureEntityMap.query(function(result) {
                vm.applications = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            ApplicationFeatureEntityMapSearch.query({query: vm.searchQuery}, function(result) {
                vm.applications = result;
            });
        }    }
})();
