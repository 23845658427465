(function() {
    'use strict';
    angular
        .module('portalApp')
        .factory('CurrencyJournal', CurrencyJournal);

    CurrencyJournal.$inject = ['$resource', 'DateUtils'];

    function CurrencyJournal ($resource, DateUtils) {
        var resourceUrl =  'api/currency-journals/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.activeFrom = DateUtils.convertLocalDateFromServer(data.activeFrom);
                        data.activeUntil = DateUtils.convertLocalDateFromServer(data.activeUntil);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.activeFrom = DateUtils.convertLocalDateToServer(data.activeFrom);
                    data.activeUntil = DateUtils.convertLocalDateToServer(data.activeUntil);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.activeFrom = DateUtils.convertLocalDateToServer(data.activeFrom);
                    data.activeUntil = DateUtils.convertLocalDateToServer(data.activeUntil);
                    return angular.toJson(data);
                }
            }
        });
    }
})();
