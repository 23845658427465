(function () {
    'use strict';

    angular
        .module('portalApp')
        .directive('timeseriesChart', timeseriesChart);

    timeseriesChart.$inject = ['$window', 'chartConfig', 'TimeseriesUtils', 'DateUtils'];

    function timeseriesChart($window, chartConfig, TimeseriesUtils, DateUtils) {
        // Generates chart for one or more timeseries
        // Usage:
        //  <div data-timeseries-chart chart-data="vm.chartData">
        //  <div data-timeseries-chart chart-data="vm.chartData" line-style="vm.lineStyle">
        //  <div data-timeseries-chart chart-data="vm.chartData" date-format="vm.dateFormat" date-frequency="vm.dateFreq">
        //  <div data-timeseries-chart chart-data="vm.chartData" shadow-range="vm.shadowRange">
        //  <div data-timeseries-chart chart-data="vm.chartData" min-date="vm.minimumDate">
        //  <div data-timeseries-chart chart-data="vm.chartData" max-date="vm.maximumDate">
        //  <div data-timeseries-chart chart-data="vm.chartData" y-label="vm.country.currency.code + ' per USD'">
        //  <div data-timeseries-chart chart-data="vm.chartData" max-x-ticks="7">
        // Input structure:
        //  vm.chartData = {
        //      chartId: "someId",
        //      title: "some title",
        //      lineSeries: [
        //          [
        //              {date: Date, value: Double},
        //              ...
        //          ],
        //          ...
        //      ]
        //  }
        //  vm.lineStyle = [
        //      {
        //          width: 2,
        //          color: '#3734FF',
        //          dashSetting: [<dashWidth>,<emptySpaceWidth>],
        //          markerSize: 2,
        //          markerShape: <one of the following shapes: None, LeftArrow, RightArrow, Circle, Cross, HorizLine,
        //                                                     VertLine, Diamond, Rectangle, Triangle, InvertedTriangle,
        //                                                     Hexagon, Pentagon, Star, Ellipse, Wedge, Trapezoid,
        //                                                     UpArrow, DownArrow>
        //      },
        //      ...
        //  ]
        //  vm.shadowRange = [
        //      {
        //          start: Date,
        //          end: Date,
        //          color: '#373737',
        //          opacity: 0.5
        //      },
        //      ...
        //  ]
        // Creates:
        //  <div data-dd-timeseries-chart class="timeseriesChart">
        var directive = {
            link: linkFunc,
            restrict: 'A',
            scope: {
                chartData: '<',
                lineStyle: '<',
                lineWidth: '<',
                dateFormat: '<',
                dateFrequency: '<',
                shadowRange: '<',
                minDate: '<',
                maxDate: '<',
                yLabel: '<',
                maxXTicks: '<'
            }
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            element.addClass('timeseriesChart');
            element.attr('id', scope.chartData.chartId);

            makeChart();

            scope.$watch('chartData', updateChart, true);
            scope.$watch('lineStyle', updateChart, true);
            scope.$watch('lineWidth', updateChart, true);
            scope.$watch('dateFormat', updateChart, true);
            scope.$watch('dateFrequency', updateChart, true);
            scope.$watch('shadowRange', updateChart, true);
            scope.$watch('minDate', updateChart, true);
            scope.$watch('maxDate', updateChart, true);
            scope.$watch('yLabel', updateChart, true);
            scope.$watch('maxXTicks', updateChart, true);
            scope.$watch(parentWidth,
                function (newVal, oldVal) {
                    // update chart after all the other stuff listening to
                    // the change of width of the parent div is done, 1 sec is enough
                    setTimeout(function () {
                        updateChart(newVal, oldVal);
                    }, 1000);
                },
                true);
            //some responsiveness here: when window was resized - recalculate
            // height of the chart based on the parent height
            // var win = angular.element($window);
            // win.bind("resize", updateChart);
            // element.on('$destroy', function () {
            //     win.unbind("resize", updateChart);
            // });

            function parentWidth() {
                return {
                    width: element.parent().width()
                };
            }

            function updateChart(newValue, oldValue) {
                if (newValue === oldValue) {
                    return;
                }
                //unbind this function before removing the chart
                // win.unbind("resize", updateChart);
                element.ejChart("destroy");
                element.empty();
                makeChart();
                // win.bind("resize", updateChart);
            }

            function makeChart() {
                // console.log('here!');
                //load defaults
                var chartObject = angular.copy(chartConfig.timeseriesChartDefaults.chartObject);
                var defaultLineStyles = angular.copy(chartConfig.timeseriesChartDefaults.lineStyles);
                var defaultDateFormat = angular.copy(chartConfig.timeseriesChartDefaults.dateFormat);
                var defaultFrequency = angular.copy(chartConfig.timeseriesChartDefaults.frequency);
                var defaultShadowStyle = angular.copy(chartConfig.timeseriesChartDefaults.shadowStyle);

                //get chart title from chartData
                // chartObject.title.text = angular.copy(scope.chartData.title);

                //get the label for Y-axis
                var yLabel = angular.copy(scope.yLabel);
                if (!yLabel)
                    yLabel = "";

                //get the max number of ticks on the X-axis
                var xTicksMax = angular.copy(scope.maxXTicks);
                if (!xTicksMax)
                    xTicksMax = 8;

                //get lines' series from chartData
                var lineData = angular.copy(scope.chartData.lineSeries);

                // confidence bands data
                var bandWidthData = angular.copy(scope.chartData.lineBands);

                //try getting lines' styles from lineStyle
                var lineStyles = angular.copy(scope.lineStyle);

                //try getting lines' styles from lineStyle
                var lineWidths = angular.copy(scope.lineWidth);

                //get lines' legends from chartData
                var lineLegends = angular.copy(scope.chartData.lineLegends);

                //try getting tick labels' date format from dateFormat
                var dateFormat = angular.copy(scope.dateFormat);
                if (!dateFormat)
                    dateFormat = defaultDateFormat;

                //try getting tick labels' date frequency from dateFrequency
                var dateFrequency = angular.copy(scope.dateFrequency);
                if (!dateFrequency)
                    dateFrequency = defaultFrequency;

                //try getting shadow range from shadowRange
                var shadowRange = angular.copy(scope.shadowRange);
                if (!shadowRange)
                    shadowRange = [];

                //try getting minimum date from minDate
                var minDate = angular.copy(scope.minDate);

                //try getting maximum date from maxDate
                var maxDate = angular.copy(scope.maxDate);

                //update chart height based on the height of the parent element
                // console.log(element.parent().height());
                // chartObject.size.height = (angular.copy(element.parent().height())).toString();

                //if there was an error don't try to build a chart
                if (lineData === null) {
                    element.append('<p>Unable to plot the chart</p>');
                    return;
                }

                //if there's no line styles provided fill them in with the default line styles
                var ix;
                if (lineData.length > 0 && (!lineStyles || lineStyles.length == 0)) {
                    lineStyles = [];
                    for (ix = 0; ix < Math.min(6, lineData.length); ix++) {
                        lineStyles.push(defaultLineStyles.predefined[ix]);
                    }
                }

                //if line data is empty show nice watermark
                if (lineData.length == 0) {
                    element.append('<div id="watermark" style="font-size:24px;display:none">Empty</div>');
                    chartObject.annotations[0].visible = true;
                    chartObject.primaryXAxis.visible = false;
                    chartObject.primaryYAxis.visible = false;
                    chartObject.primaryXAxis.majorGridLines.visible = false;
                    chartObject.primaryYAxis.majorGridLines.visible = false;
                    chartObject.primaryYAxis.title.visible = false;
                    chartObject.series = [{}];
                    chartObject.primaryXAxis.stripLine = [];
                    chartObject.legend.visible = false;
                } else {
                    chartObject.annotations[0].visible = false;
                    chartObject.primaryXAxis.visible = true;
                    chartObject.primaryYAxis.visible = true;
                    chartObject.primaryXAxis.majorGridLines.visible = true;
                    chartObject.primaryYAxis.majorGridLines.visible = true;
                    chartObject.primaryYAxis.title.visible = true;
                    chartObject.primaryYAxis.title.text = yLabel;
                    chartObject.series = [];
                    chartObject.primaryXAxis.stripLine = [];
                    chartObject.legend.visible = true;
                }
                //cut the range of all chart series
                for (ix = 0; ix < lineData.length; ix++) {
                    if (angular.isDefined(lineData[ix])) {
                        if (minDate)
                            lineData[ix] = lineData[ix].filter(function (dataPoint) {
                                if (angular.isUndefined(dataPoint))
                                    return false;
                                return new Date(dataPoint.date) >= minDate;
                            });
                        if (maxDate)
                            lineData[ix] = lineData[ix].filter(function (dataPoint) {
                                if (angular.isUndefined(dataPoint))
                                    return false;
                                return new Date(dataPoint.date) <= maxDate;
                            });
                    }
                }
                //configure shadow ranges
                if (lineData.length > 0)
                    for (ix = 0; ix < shadowRange.length; ix++) {
                        var ixc = angular.copy(ix);
                        var sr = angular.copy(shadowRange[ixc]);
                        sr.visible = true;
                        //todo: lineData[0] is a nasty assumption -- use the series which starts the earliest instead
                        if (shadowRange[ixc].start) {
                            var convertedDate = DateUtils.convertLocalDateToServer(
                                TimeseriesUtils.parseTsDate(
                                    TimeseriesUtils.convertDateToTsDate(shadowRange[ixc].start, dateFrequency, dateFormat), dateFrequency
                                )
                            );
                            sr.start = lineData[0].findIndex(function (d) {
                                return d.date == convertedDate;
                            });
                        } else {
                            sr.start = 0;
                        }
                        if (shadowRange[ixc].end) {
                            var convertedDate = DateUtils.convertLocalDateToServer(
                                TimeseriesUtils.parseTsDate(
                                    TimeseriesUtils.convertDateToTsDate(shadowRange[ixc].end, dateFrequency, dateFormat), dateFrequency
                                )
                            );
                            sr.end = lineData[0].findIndex(function (d) {
                                return d.date == convertedDate;
                            });
                        } else {
                            if (shadowRange[ixc].start) {
                                sr.end = lineData[0].length - 1;
                            } else {
                                sr.end = 0;
                            }
                        }
                        for (var styleProperty in defaultShadowStyle) {
                            if (defaultShadowStyle.hasOwnProperty(styleProperty) && !shadowRange[ixc].hasOwnProperty(styleProperty)) {
                                sr[styleProperty] = defaultShadowStyle[styleProperty];
                            }
                        }
                        chartObject.primaryXAxis.stripLine.push(sr);
                    }
                //fill in series of chartObject with the lines' data
                var minValue = Number.MAX_VALUE;
                var maxValue = Number.MIN_VALUE;
                var uniqueTicks = [];
                for (ix = 0; ix < lineData.length; ix++) {
                    if (angular.isDefined(lineData[ix]) && lineData[ix].length && lineData[ix].length > 1) {
                        var bandData = [];
                        const hasBand = (angular.isDefined(bandWidthData[ix]) && bandWidthData[ix] !== 0);
                        //convert dates to formatted date strings
                        for (var jx = 0; jx < lineData[ix].length; jx++) {
                            const yNum = lineData[ix][jx].value;
                            const dt = lineData[ix][jx].date;
                            minValue = Math.min(minValue, yNum);
                            maxValue = Math.max(maxValue, yNum);
                            lineData[ix][jx].dateFormatted = TimeseriesUtils.convertDateToTsDate(dt, dateFrequency, dateFormat);
                            if (!uniqueTicks.includes(dt)) {
                                uniqueTicks.push(dt);
                            }
                            if (hasBand) {
                                bandData.push({
                                    dateFormatted: lineData[ix][jx].dateFormatted,
                                    upper: yNum + bandWidthData[ix],
                                    lower: yNum - bandWidthData[ix]
                                });
                            }
                        }
                        //use default line style if number of lines is higher the number of predefined lines in config
                        if (lineStyles.length < ix + 1) {
                            lineStyles.push(defaultLineStyles.new);
                        }
                        //adjust line width if user supplied one
                        if (lineWidths.length >= ix + 1 && lineWidths[ix]) {
                            lineStyles[ix].width = lineWidths[ix];
                        }
                        //use default legend if lineLegends array is shorter
                        var lineName = "";
                        if (!lineLegends || lineLegends.length < ix + 1) {
                            lineName = "curve" + (ix + 1);
                        } else {
                            lineName = lineLegends[ix];
                        }
                        // add confidence bands for lines
                        if (bandData && bandData.length > 0) {
                            chartObject.series.push({
                                fill: '#dbdbe6',
                                tooltip: {font: {color: '#1e2834'}},
                                border: {color: 'transparent'},
                                type: 'rangearea',
                                dataSource: bandData,
                                name: lineName + " ±1 stdev",
                                xName: "dateFormatted",
                                high: "upper",
                                low: "lower"
                            });
                        }
                        //add series to the chart structure
                        chartObject.series.push({
                            fill: lineStyles[ix].color,
                            width: lineStyles[ix].width,
                            marker: {
                                visible: false,
                                shape: lineStyles[ix].markerShape,
                                size: {width: lineStyles[ix].markerSize, height: lineStyles[ix].markerSize}
                            },
                            dashArray: lineStyles[ix].dashSetting.toString(),
                            type: 'line',
                            dataSource: lineData[ix],
                            xName: "dateFormatted",
                            yName: "value",
                            name: lineName
                        });
                    }
                }

                // add invisible series to the beginning of chartObject.series array
                // to correctly handle the X axis ticks
                uniqueTicks.sort();
                var dataPoints = [];
                const startDate = uniqueTicks[0];
                const endDate = uniqueTicks[uniqueTicks.length - 1];
                for (var tx = 0; tx < uniqueTicks.length; tx++) {
                    const tick = uniqueTicks[tx];
                    dataPoints.push({
                        x: TimeseriesUtils.convertDateToTsDate(tick, dateFrequency, dateFormat),
                        y: -100
                    });
                }
                chartObject.series.unshift({
                    fill: "transparent",
                    type: 'line',
                    points: dataPoints,
                    name: " "
                });
                // adjust X-axis interval to be at most 9 ticks
                const nTicks = uniqueTicks.length;
                chartObject.primaryXAxis.range = {interval: Math.ceil(nTicks / xTicksMax)};
                // adjust Y-axis range to be tight
                var delta = Math.abs(maxValue - minValue);
                if (lineData.length > 0) {
                    chartObject.primaryYAxis.range = {
                        min: minValue - 0.1 * delta,
                        max: maxValue + 0.1 * delta
                    };
                }
                // console.log(chartObject);
                //generate the chart from prepared object
                element.ejChart(angular.copy(chartObject));
            }
        }
    }

})();
