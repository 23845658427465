(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('ApplicationFeatureEntityMapDialogController', ApplicationFeatureEntityMapDialogController);

    ApplicationFeatureEntityMapDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ApplicationFeatureEntityMap', 'ApplicationFeature'];

    function ApplicationFeatureEntityMapDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ApplicationFeatureEntityMap, ApplicationFeature) {
        var vm = this;

        vm.applicationFeatures = null;
        vm.applicationFeatureEntityMap = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        ApplicationFeature.query(function (applicationFeatures) {
            vm.applicationFeatures = applicationFeatures; //.map(function(obj){return obj.name});
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.applicationFeatureEntityMap.id !== null) {
                ApplicationFeatureEntityMap.update(vm.applicationFeatureEntityMap, onSaveSuccess, onSaveError);
            } else {
                ApplicationFeatureEntityMap.save(vm.applicationFeatureEntityMap, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('portalApp:applicationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
