(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('ResultSeriesDialogController', ResultSeriesDialogController);

    ResultSeriesDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'ResultSeries', 'Result', 'Timeseries'];

    function ResultSeriesDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, ResultSeries, Result, Timeseries) {
        var vm = this;

        vm.resultSeries = entity;
        vm.clear = clear;
        vm.save = save;
        vm.results = Result.query();
        vm.timeseriess = Timeseries.query({filter: 'resultseries-is-null'});
        $q.all([vm.resultSeries.$promise, vm.timeseriess.$promise]).then(function() {
            if (!vm.resultSeries.timeseriesId) {
                return $q.reject();
            }
            return Timeseries.get({id : vm.resultSeries.timeseriesId}).$promise;
        }).then(function(timeseries) {
            vm.timeseries.push(timeseries);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.resultSeries.id !== null) {
                ResultSeries.update(vm.resultSeries, onSaveSuccess, onSaveError);
            } else {
                ResultSeries.save(vm.resultSeries, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('portalApp:resultSeriesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
