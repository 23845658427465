(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('ModelDetailController', ModelDetailController);

    ModelDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Model', 'Frequency', 'Round', 'ModelEquation', 'ModelSymbol'];

    function ModelDetailController($scope, $rootScope, $stateParams, entity, Model, Frequency, Round, ModelEquation, ModelSymbol) {
        var vm = this;

        vm.model = entity;

        var unsubscribe = $rootScope.$on('portalApp:modelUpdate', function(event, result) {
            vm.model = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
