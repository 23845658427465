(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('ApplicationFeatureController', ApplicationFeatureController);

    ApplicationFeatureController.$inject = ['$scope', '$state', 'ApplicationFeature', 'ApplicationFeatureSearch'];

    function ApplicationFeatureController ($scope, $state, ApplicationFeature, ApplicationFeatureSearch) {
        var vm = this;

        vm.applications = [];
        vm.search = search;

        loadAll();

        function loadAll() {
            ApplicationFeature.query(function(result) {
                vm.applications = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            ApplicationFeatureSearch.query({query: vm.searchQuery}, function(result) {
                vm.applications = result;
            });
        }    }
})();
