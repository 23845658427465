(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('SubscriptionDetailController', SubscriptionDetailController);

    SubscriptionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Subscription', 'UserGroup', 'Application', 'Country'];

    function SubscriptionDetailController($scope, $rootScope, $stateParams, entity, Subscription, UserGroup, Application, Country) {
        var vm = this;

        vm.subscription = entity;

        var unsubscribe = $rootScope.$on('portalApp:subscriptionUpdate', function(event, result) {
            vm.subscription = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
