(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('StatisticsController', StatisticsController);

    StatisticsController.$inject = ['$filter', 'StatisticsService'];

    function StatisticsController ($filter, StatisticsService) {
        var vm = this;

        vm.statistics = null;

        loadAll();

        function loadAll () {
            StatisticsService.query({}, function(result){
                vm.statistics = result;
            });
        }
    }
})();
