(function() {
    'use strict';
    angular
        .module('portalApp')
        .factory('VerifySubscription', VerifySubscription);

    VerifySubscription.$inject = ['$resource'];

    function VerifySubscription ($resource) {

        var resourceUrl =  'api/verify-subscription';

        return $resource(resourceUrl, {}, {
            'verify': {
                method: 'GET',
                isArray: false,
                interceptor: {
                    response: function(response) {
                        // expose response
                        return response;
                    }
                }
            }
        });
    }
})();
