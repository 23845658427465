(function() {
    'use strict';

    angular
        .module('portalApp')
        .factory('ApplicationFeatureUserGroupMapSearch', ApplicationFeatureUserGroupMapSearch);

    ApplicationFeatureUserGroupMapSearch.$inject = ['$resource'];

    function ApplicationFeatureUserGroupMapSearch($resource) {
        var resourceUrl =  'api/_search/application-feature-user-group-maps/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
