(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('UserGroupDetailController', UserGroupDetailController);

    UserGroupDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'UserGroup'];

    function UserGroupDetailController($scope, $rootScope, $stateParams, entity, UserGroup) {
        var vm = this;

        vm.userGroup = entity;

        var unsubscribe = $rootScope.$on('portalApp:userGroupUpdate', function(event, result) {
            vm.userGroup = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
