(function() {
    'use strict';

    angular
        .module('portalApp')
        .factory('TranslationSearch', TranslationSearch);

    TranslationSearch.$inject = ['$resource'];

    function TranslationSearch($resource) {
        var resourceUrl =  'api/_search/translations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
