(function () {
    'use strict';
    angular
        .module('portalApp')
        .controller('YcmController', YcmController);

    YcmController.$inject = ['$filter', '$state', 'subscribedCountryList', 'latestYcmRound', 'ycmResources',
        'DataUtils', 'Resource', 'YieldCurve', 'ApplicationSeriesData', '$window', '$q'];

    function YcmController($filter, $state, subscribedCountryList, latestYcmRound, ycmResources,
                           DataUtils, Resource, YieldCurve, ApplicationSeriesData, $window, $q) {
        var vm = this;
        vm.downloadResource = downloadResource;
        vm.onDownload = onDownload;
        vm.chartDataFromYcmResults = chartDataFromYcmResults;
        vm.fxChartDataFromResponse = fxChartDataFromResponse;
        vm.showNote = false;
        vm.country = subscribedCountryList.find(function (c) {
            return (c.id == $state.params.countryId);
        });
        vm.latestYcmRound = latestYcmRound;
        vm.isFullFpas = true;
        vm.chartData = {
            chartId: "yc-chart-" + vm.country.code,
            lineSeries: {irs: [], ccy: [], zero: [], irsSr: []},
            lineLegends: []
        };
        vm.chartMinTenor = 0;
        vm.chartMaxTenor = 3653;
        vm.curveType = "zero";
        vm.yAxisTitle = "Yield (% pa, continuous)";
        vm.langKey = 'en';
        vm.fxChartData = {
            chartId: "yc-fx-chart-" + vm.country.code,
            lineSeries: [],
            lineLegends: [],
            lineBands: []
        };
        vm.yAxisTitleFx = vm.country.currency.code + " per USD";
        // if round is not found (doesn't exist or not subscribed) => stop here
        if (latestYcmRound !== null) {
            // pre-process the resources (add descriptions, icon types, ordering etc.)
            vm.ycmResources = ycmResources.map(function (res) {
                if (res.filename.indexOf(".csv") !== -1) {
                    res.ix = 0;
                    res.desc = "Daily Benchmark Yield Curve Spreadsheet";
                    res.ext = "csv";
                    res.icon = "fa fa-file-excel-o fa-fw";
                } else {
                    res.ix = 1;
                    res.desc = "Unknown file";
                    res.ext = "";
                    res.icon = "fa fa-file-o fa-fw";
                }
                return res;
            });
            // fetch yield curves and populate the chart data
            YieldCurve
                .query({
                    roundId: vm.latestYcmRound.id,
                    actionCode: 'rc_macro'
                })
                .$promise
                .then(chartDataFromYcmResults)
                .catch(function () {
                    return [];
                });
            // fetch FX outright series and populate the FX chart data
            var proms = [];
            var fxNames = ['fxOutright', 'fxOutright1MAgo', 'fxOutright3MAgo'];
            angular.forEach(fxNames, function (fxName) {
                proms.push(
                    ApplicationSeriesData.query({
                        roundId: vm.latestYcmRound.id,
                        actionCode: 'rc_macro',
                        applicationVariable: fxName,
                        applicationId: 4,
                        noDateLimit: true
                    }).$promise);
            });
            $q.all(proms)
                .then(fxChartDataFromResponse)
                .catch(function () {
                    return [];
                })
        }

        /**
         * Triggers downloading -- either proper resource downloading from SVN or a data export
         * @param res      resource object
         */
        function downloadResource(res) {
            vm.onDownload(res.desc + ': ' + vm.country.name);
            Resource
                .download({id: res.id, download: true})
                .$promise
                .then(function (response) {
                    var fileNameIndex = response.headers["content-disposition"].indexOf("filename=");
                    var fileName = vm.country.name + " -- " + res.desc + " ("
                        + $filter('date')(vm.latestYcmRound.created, 'dd-MMM-yyyy')
                        + ")" + ((res.ext === "") ? "" : ("." + res.ext));
                    var fileNameInRepo = "unknown";
                    if (fileNameIndex > -1) {
                        fileNameInRepo = response.headers["content-disposition"].substr(fileNameIndex + "filename=".length);
                    }
                    DataUtils.saveFile(response.headers["content-type"],
                        response.data, fileName);
                });

        }

        /**
         * Inform google analytics about file download started
         * @param fileLabel
         */
        function onDownload(fileLabel) {
            $window.dataLayer = $window.dataLayer || [];
            fileLabel = fileLabel || "Unknown file";
            $window.dataLayer.push({
                'event': 'download',
                'category': 'document',
                'action': 'download',
                'label': fileLabel
            });
        }

        function fxChartDataFromResponse(response) {
            if (response && response.length) {
                vm.fxChartData.lineSeries = [];
                const fxLegends = [
                    'Forward Outright, Current',
                    'Forward Outright, 1M ago',
                    'Forward Outright, 3M ago'
                ];
                vm.fxChartData.lineBands = [];
                for (var i = 0; i < response.length; i++) {
                    const ts = response[i];
                    if (ts && ts.length) {
                        const tsData = ts.filter(function (d) {
                            return d.value !== 'NaN';
                        });
                        // sort timeseries by dates
                        if (angular.isArray(tsData) && tsData.length > 1) {
                            vm.fxChartData.lineSeries.push(tsData
                                .sort(function (a, b) {
                                    return new Date(a.date) - new Date(b.date);
                                }));
                            vm.fxChartData.lineLegends.push(fxLegends[i]);
                            vm.fxChartData.lineBands.push(0);
                        }
                    }
                }
            }
        }

        /**
         * Converts YCM results to the input structure for yc-chart directive
         * @param ycmResults -- resulting array of curves
         */
        function chartDataFromYcmResults(ycmResults) {
            const curves = ['curveLatest', 'curve1MAgo', 'curve3MAgo'];
            for (var i = 0; i < curves.length; i++) {
                const cName = curves[i];
                const res = ycmResults.find(function (el) {
                    return el.name === cName;
                });
                if (angular.isUndefined(res) || angular.isUndefined(res.yieldCurveData)) {
                    vm.chartData.lineSeries.zero.push([]);
                } else {
                    vm.chartData.lineSeries.zero.push(res.yieldCurveData.map(reduceYcData));
                    vm.chartData.lineLegends.push(res.label);
                }
            }
        }

        /**
         * Slims down the object to contain only those fields needed for chart
         * @param ycData -- array of yield curve data points
         * @returns {{tenorDays: *, tenorLabel: *, value: *}}
         */
        function reduceYcData(ycData) {
            return {
                tenorDays: ycData.tenorDays,
                tenorLabel: ycData.tenorLabel,
                value: ycData.value
            };
        }
    }
})();



