(function () {
    'use strict';
    angular
        .module('portalApp')
        .controller('ExternalController', ExternalController);

    ExternalController.$inject = ['externalRound', 'extResources', '$window'];

    function ExternalController(externalRound, extResources, $window) {
        var vm = this;
        vm.onDownload = onDownload;

        vm.externalRound = externalRound;
        vm.extResources = (!extResources || !extResources.length) ? [] : extResources.map(function (res) {
            if (res.filename.indexOf("csv") !== -1) {
                res.desc = "Global Projections Database";
                res.icon = "fa fa-file-excel-o fa-fw";
            } else if (res.filename.indexOf("pdf") !== -1) {
                res.desc = "Global Projections Report";
                res.icon = "fa fa-file-pdf-o fa-fw";
            } else {
                res.desc = "Unknown file";
                res.icon = "fa fa-file-o fa-fw";
            }
            return res;
        });

        function onDownload(fileLabel) {
            $window.dataLayer = $window.dataLayer || [];
            fileLabel = fileLabel || "Unknown file";
            $window.dataLayer.push({
                'event': 'download',
                'category': 'document',
                'action': 'download',
                'label': fileLabel
            });
        }
    }
})();



