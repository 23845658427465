(function () {
    'use strict';

    angular
        .module('portalApp')
        .controller('RatingsController', RatingsController);

    RatingsController.$inject = ['$filter', '$state', '$translate', 'latestEwsRound', 'latestMrmRound', 'latestCrrRound', 'latestFpasRound',
        'mrmResources', 'crrResources', 'riskAndRatingData', 'subscribedCountryList', 'SeriesExport',
        'DataUtils', 'DateUtils', 'Resource', '$window', 'latestYcmRound', 'subscribedMRMCountryList'];

    function RatingsController($filter, $state, $translate, latestEwsRound, latestMrmRound, latestCrrRound, latestFpasRound,
                               mrmResources, crrResources, riskAndRatingData, subscribedCountryList, SeriesExport, DataUtils, DateUtils, Resource,
                               $window, latestYcmRound, subscribedMRMCountryList) {
        var vm = this;

        vm.country = subscribedCountryList.find(function (c) {
            return (c.id == $state.params.countryId);
        });

        if (latestEwsRound !== null) {
            vm.latestEwsRound = latestEwsRound;
        } else {
            vm.latestEwsRound = latestMrmRound;
        }
        vm.latestMrmRound = latestMrmRound;
        vm.latestCrrRound = latestCrrRound;

        vm.latestFpasRound = latestFpasRound;
        vm.latestYcmRound = latestYcmRound;
        vm.riskAndRatingData = riskAndRatingData.find(function (c) {
            return (c.id == $state.params.countryId);
        });
        vm.allCountriesMrmLastCreated = new Date(subscribedMRMCountryList
            .map(function (c) {
                return c.latestRoundCreatedDate;
            })
            .sort()
            .reverse()[0]);
        // console.log(vm.riskAndRatingData);
        vm.ratingResources = [];
        vm.riskResources = [];
        vm.excelName = "";
        vm.excelFileDesc = "Exchange Rate Risk Distribution";
        vm.showFxRateInfo = false;
        vm.showFxRateChartInfo = false;
        vm.chartMinDate = new Date();
        vm.chartMaxDate = new Date();
        vm.downloadExcel = downloadExcel;
        vm.onDownload = onDownload;
        vm.downloadResource = downloadResource;
        if (crrResources !== null && crrResources.length !== 0) {
            vm.ratingResources = crrResources.filter(function (res) {
                return res.resourceKeyName === "CRR-RATING-PDF" || res.resourceKeyName === "EWS-RATING-PDF"
            });
        }
        if (vm.latestEwsRound !== null) {
            vm.excelName = vm.latestEwsRound.countryName + "_fx-rate-macro-risk_" + $filter('date')(vm.latestEwsRound.created, 'dd-MMM-yyyy') + ".xlsx";
            if (mrmResources !== null && mrmResources.length !== 0) {
                vm.riskResources = mrmResources.filter(function (res) {
                    return res.resourceKeyName === "MRM-RISK-PDF" || res.resourceKeyName === "EWS-RISK-PDF"
                });
            }
        }


        /**
         * Triggers downloading -- either proper resource downloading from SVN or a data export
         * @param res      resource object
         */
        function downloadResource(res) {
            var reportName, reportCreated;
            if (res.resourceKeyName === "CRR-RATING-PDF" || res.resourceKeyName === "EWS-RATING-PDF") {
                reportName = "Country Rating Report";
                reportCreated = vm.latestCrrRound.created;
            } else {
                reportName = "Macro Risk Report";
                reportCreated = vm.latestEwsRound.created;
            }

            vm.onDownload(reportName + ': ' + vm.country.name);
            Resource
                .download({id: res.id, download: true})
                .$promise
                .then(function (response) {
                    var fileName = vm.country.name + " -- " + reportName + " ("
                        + $filter('date')(reportCreated, 'dd-MMM-yyyy') + ")" + ".pdf";
                    DataUtils.saveFile(response.headers["content-type"],
                        response.data, fileName);
                });

        }

        function onDownload(fileLabel) {
            $window.dataLayer = $window.dataLayer || [];
            fileLabel = fileLabel || "Unknown file";
            $window.dataLayer.push({
                'event': 'download',
                'category': 'document',
                'action': 'download',
                'label': fileLabel
            });
        }

        function downloadExcel() {
            vm.onDownload(vm.excelName);
            SeriesExport('excel')
                .query({
                    tsId: ewsTimeseriesData.slice(1).map(function (val) {
                        return val[0].timeseriesId;
                    }),
                    tsDesc: ewsTimeseriesData[0].map(function (val) {
                        return (val === "fxMean")
                            ? "Mean"
                            : val.replace(/fx(\w+?)_(\d+)/, "$1 $2%");
                    }),
                    rangeStart: DateUtils.convertLocalDateToServer(vm.chartMinDate),
                    rangeEnd: DateUtils.convertLocalDateToServer(vm.chartMaxDate),
                    sheetName: "USD" + vm.country.currency.code.toUpperCase(),
                    format: "xlsx",
                    languageKey: $translate.storage().get('NG_TRANSLATE_LANG_KEY')
                })
                .$promise
                .then(function (response) {
                    DataUtils.saveFile(response.headers["content-type"],
                        response.data, vm.excelName);
                });
        }
    }
})();

