(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('CompaniesStatisticsController', CompaniesStatisticsController);

    CompaniesStatisticsController.$inject = ['CompaniesStatisticsService'];

    function CompaniesStatisticsController (CompaniesStatisticsService) {
        var vm = this;

        vm.statistics = null;

        loadAll();

        function loadAll () {
            CompaniesStatisticsService.query({}, function(result){
                vm.statistics = result;
            });
        }
    }
})();
