(function() {
    'use strict';
    angular
        .module('portalApp')
        .factory('FooterService', FooterService);

    FooterService.$inject = ['$resource'];

    function FooterService ($resource) {
        var resourceUrl =  'api/open/app-version';

        return $resource(resourceUrl);
    }
})();
