(function () {
    'use strict';

    angular
        .module('portalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('main', {
                parent: 'home',
                url: 'dashboard/',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'main.pageTitle.dashboard'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/main/main.html',
                        controller: 'MainController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('main');
                        return $translate.refresh();
                    }],
                    subscribedCountryList: ['SubscribedCountries', function (SubscribedCountries) {
                        return SubscribedCountries.query({}).$promise;
                    }],
                    subscribedFPASCountryList: ['SubscribedCountries', function (SubscribedCountries) {
                        return SubscribedCountries.query({productCode: "FPAS"}).$promise;
                    }],
                    subscribedMRMCountryList: ['SubscribedCountries', function (SubscribedCountries) {
                        return SubscribedCountries.query({productCode: "MRM"}).$promise;
                    }],
                    subscribedCRRCountryList: ['SubscribedCountries', function (SubscribedCountries) {
                        return SubscribedCountries.query({productCode: "CRR"}).$promise;
                    }],
                    subscribedYCCountryList: ['SubscribedCountries', function (SubscribedCountries) {
                        return SubscribedCountries.query({productCode: "YCM_NSS"}).$promise;
                    }],
                    riskAndRatingData: ['CountryRiskAndRating', function (CountryRiskAndRating) {
                        return CountryRiskAndRating
                            .query({riskHorizon: -0.03, ratingHorizon: -0.03})
                            .$promise;
                    }]
                }
            })
            .state('externalSector', {
                parent: 'main',
                url: 'external/{roundId}',
                params: {
                    roundId: null
                },
                data: {
                    pageTitle: 'main.pageTitle.externalPage'
                },
                views: {
                    'external': {
                        templateUrl: 'app/external/external.html',
                        controller: 'ExternalController',
                        controllerAs: 'vm',
                        resolve: {
                            extResources: ['$q', 'ResourceLink', 'externalRound', function ($q, ResourceLink, externalRound) {
                                if (externalRound === null) {
                                    return $q.when({});
                                }
                                return ResourceLink
                                    .query({
                                        id: externalRound.id,
                                        productCode: "FPAS"
                                    })
                                    .$promise;
                            }]
                        }
                    }
                },
                resolve: {
                    externalRound: ['$stateParams', '$q', 'Round', function ($stateParams, $q, Round) {
                        if ($stateParams.roundId === null || (isNaN(parseInt($stateParams.roundId)) && $stateParams.roundId !== "latest" )) {
                            return $q.when(null);
                        }
                        if ($stateParams.roundId === "latest") {
                            return Round.get({
                                "countryId": 73,
                                "productCode": "FPAS",
                                "applicationName": "portal",
                                "typeFilter": "regular"
                            }).$promise
                                .catch(function () {
                                    return null;
                                });
                        }
                        return Round.get({
                            "id": $stateParams.roundId
                        }).$promise
                            .catch(function () {
                                return null;
                            });
                    }]
                }
            })
            .state('selectedCountry', {
                parent: 'main',
                url: 'country-page/{countryId}',
                data: {
                    pageTitle: 'main.pageTitle.countryPage'
                },
                params: {
                    countryId: null,
                    countryName: null
                },
                views: {
                    'ratings': {
                        templateUrl: 'app/ratings/ratings.html',
                        controller: 'RatingsController',
                        controllerAs: 'vm',
                        resolve: {
                            mrmResources: ['$q', 'ResourceLink', 'latestMrmRound', function ($q, ResourceLink, latestMrmRound) {
                                if (latestMrmRound === null) {
                                    return $q.when([]);
                                }
                                return ResourceLink
                                    .query({
                                        id: latestMrmRound.id,
                                        productCode: "MRM"
                                    })
                                    .$promise;
                            }],
                            crrResources: ['$q', 'ResourceLink', 'latestCrrRound', function ($q, ResourceLink, latestCrrRound) {
                                if (latestCrrRound === null) {
                                    return $q.when([]);
                                }
                                return ResourceLink
                                    .query({
                                        id: latestCrrRound.id,
                                        productCode: "CRR"
                                    })
                                    .$promise;
                            }]
                        }
                    },
                    'fpas': {
                        templateUrl: 'app/fpas/fpas.html',
                        controller: 'FpasController',
                        controllerAs: 'vm',
                        resolve: {
                            fpasTimeseriesData: ['$q', '$stateParams', 'FpasSeries', 'latestFpasRound', 'subscribedCountryList', function ($q, $stateParams, FpasSeries, latestFpasRound, subscribedCountryList) {
                                if (latestFpasRound === null) {
                                    return $q.when([]);
                                }
                                var countryObj = subscribedCountryList.find(function (c) {
                                    return (c.id == $stateParams.countryId);
                                });
                                return FpasSeries.query({
                                    "roundId": latestFpasRound.id,
                                    "actionCode": 'sm',
                                    "applicationVariable": countryObj.currency.code.toLowerCase() + '_usd'
                                }).$promise;
                            }],
                            fpasResources: ['$q', 'ResourceLink', 'latestFpasRound', function ($q, ResourceLink, latestFpasRound) {
                                if (latestFpasRound === null) {
                                    return $q.when([]);
                                }
                                return ResourceLink
                                    .query({
                                        id: latestFpasRound.id,
                                        productCode: "FPAS"
                                    })
                                    .$promise;
                            }]
                        }
                    },
                    'ycm': {
                        templateUrl: 'app/ycm-nss/ycm-nss.html',
                        controller: 'YcmController',
                        controllerAs: 'vm',
                        resolve: {
                            ycmResources: ['$q', 'ResourceLink', 'latestYcmRound', function ($q, ResourceLink, latestYcmRound) {
                                if (latestYcmRound === null) {
                                    return $q.when([]);
                                }
                                return ResourceLink
                                    .query({
                                        id: latestYcmRound.id,
                                        productCode: "YCM_NSS"
                                    })
                                    .$promise;
                            }]
                        }
                    },
                    'country-note': {
                        templateUrl: 'app/country-note/country-note.html',
                        controller: 'CountryNoteController',
                        controllerAs: 'vm',
                        resolve: {
                            countryNotes: ['$stateParams', 'CountryNote', function ($stateParams, CountryNote) {
                                return CountryNote.query({
                                    "countryId": $stateParams.countryId
                                }).$promise
                                    .catch(function () {
                                        return null;
                                    });
                            }]
                        }
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', 'subscribedCountryList', '$stateParams', '$state',
                        function ($translate, $translatePartialLoader, subscribedCountryList, $stateParams, $state) {
                            $translatePartialLoader.addPart('country');
                            var countryObj = subscribedCountryList.find(function (c) {
                                return (c.id == $stateParams.countryId);
                            });
                            $stateParams.countryName = countryObj.name;
                            return $translate.refresh();
                        }],
                    latestFpasRound: ['$stateParams', 'Round', function ($stateParams, Round) {
                        return Round.get({
                            "countryId": $stateParams.countryId,
                            "productCode": "FPAS",
                            "applicationName": "fpas"
                        }).$promise
                            .catch(function () {
                                return null;
                            });
                    }],
                    latestFullFpasRound: ['$stateParams', 'Round', function ($stateParams, Round) {
                        return Round.get({
                            "countryId": $stateParams.countryId,
                            "productCode": "FPAS",
                            "applicationName": "fpas",
                            "typeFilter": "regular"
                        }).$promise
                            .catch(function () {
                                return null;
                            });
                    }],
                    latestEwsRound: ['$stateParams', 'Round', function ($stateParams, Round) {
                        return Round.get({
                            "countryId": $stateParams.countryId,
                            "productCode": "EWS",
                            "applicationName": "ews"
                        }).$promise
                            .catch(function () {
                                return null;
                            });
                    }],
                    latestMrmRound: ['$stateParams', 'Round', function ($stateParams, Round) {
                        return Round.get({
                            "countryId": $stateParams.countryId,
                            "productCode": "MRM",
                            "applicationName": "ews"
                        }).$promise
                            .catch(function () {
                                return null;
                            });
                    }],
                    latestCrrRound: ['$stateParams', 'Round', function ($stateParams, Round) {
                        return Round.get({
                            "countryId": $stateParams.countryId,
                            "productCode": "CRR",
                            "applicationName": "ews"
                        }).$promise
                            .catch(function () {
                                return null;
                            });
                    }],
                    latestYcmRound: ['$stateParams', 'Round', function ($stateParams, Round) {
                        return Round.get({
                            "countryId": $stateParams.countryId,
                            "productCode": "YCM_NSS",
                            "applicationName": "portal"
                        }).$promise
                            .catch(function () {
                                return null;
                            });
                    }]
                }
            })
    }
})();
