(function () {
    'use strict';

    angular
        .module('portalApp')
        .factory('translationHandler', translationHandler);

    translationHandler.$inject = ['$rootScope', '$window', '$state', '$translate', '$localStorage', 'Translation',
        'amMoment'];

    function translationHandler($rootScope, $window, $state, $translate, $localStorage, Translation, amMoment) {
        var k = 0;
        return {
            initialize: initialize,
            updateTitle: updateTitle
        };

        function initialize() {
            $localStorage.translationsTable = {}; //$localStorage.translationsTable || {};
            //if the current translation changes, update the window title and other labels
            var translateChangeSuccess = $rootScope.$on('$translateChangeSuccess', function () {
                var langKey = $translate.storage().get('NG_TRANSLATE_LANG_KEY').trim().toLowerCase();
                updateTitle();
                //update translations of the labels stored in the database Translation table
                angular.forEach(['reportPageTitles', 'ntfVariableLabels', 'equationLabels',
                        'symbolLabels'],
                    function (objName) {
                        updateTranslationsFor(langKey, objName);
                    });
                angular.forEach(['name', 'description_short', 'description_detailed'], function (objName) {
                    updateTranslationsFor(langKey, 'precookedScenarioLabels', objName);
                });
                angular.forEach(['title', 'unit'], function (objName) {
                    updateTranslationsFor(langKey, 'reportPageChartTitles', objName);
                });

                //change dates locale for momentJs
                amMoment.changeLocale(langKey);
            });

            $rootScope.$on('$destroy', function () {
                if (angular.isDefined(translateChangeSuccess) && translateChangeSuccess !== null) {
                    translateChangeSuccess();
                }
            });
        }

        // update the window title using params in the following
        // precendence
        // 1. titleKey parameter
        // 2. $state.$current.data.pageTitle (current state page title)
        // 3. 'global.title'
        function updateTitle(titleKey) {
            if (!titleKey && $state.$current.data && $state.$current.data.pageTitle) {
                titleKey = $state.$current.data.pageTitle;
            }
            var paramSet = {};
            if (titleKey && $state.params && $state.params.countryName) {
                paramSet = {countryName: $state.params.countryName};
            }
            $translate(titleKey || 'global.title', paramSet).then(function (title) {
                $window.document.title = title;
            });
        }

        function updateTranslationsFor(langKey, objName, subObjName) {
            var mainState = $state.get('main');
            mainState.data = mainState.data || {};
            mainState.data[objName] = mainState.data[objName] || [];
            angular.forEach(mainState.data[objName], function (obj) {
                obj = (typeof subObjName === 'undefined') ? obj : obj[subObjName];
                if (angular.isDefined(obj.keyPhrase) && obj.keyPhrase !== "") {
                    if (!$localStorage.translationsTable.hasOwnProperty(obj.keyPhrase)
                        || !$localStorage.translationsTable[obj.keyPhrase].hasOwnProperty(langKey)) {
                        $localStorage.translationsTable[obj.keyPhrase] =
                            $localStorage.translationsTable[obj.keyPhrase] || {};
                        $localStorage.translationsTable[obj.keyPhrase][langKey] =
                            $localStorage.translationsTable[obj.keyPhrase][langKey] || '';
                        Translation.get({
                            keyPhrase: obj.keyPhrase,
                            languageKey: langKey
                        }).$promise
                            .then(function (data) {
                                obj.translated = data.translation;
                                $localStorage.translationsTable[obj.keyPhrase][langKey] = data.translation;
                            })
                            .catch(function () {
                                obj.translated = obj.keyPhrase;
                                $localStorage.translationsTable[obj.keyPhrase][langKey] = obj.keyPhrase;
                            });
                    }
                    else
                        obj.translated = $localStorage.translationsTable[obj.keyPhrase][langKey];
                }
            });
        }
    }
})();
