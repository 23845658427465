(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('ResultSeriesDetailController', ResultSeriesDetailController);

    ResultSeriesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ResultSeries', 'Result', 'Timeseries'];

    function ResultSeriesDetailController($scope, $rootScope, $stateParams, entity, ResultSeries, Result, Timeseries) {
        var vm = this;

        vm.resultSeries = entity;

        var unsubscribe = $rootScope.$on('portalApp:resultSeriesUpdate', function(event, result) {
            vm.resultSeries = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
