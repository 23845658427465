(function () {
    'use strict';

    angular
        .module('portalApp')
        .factory('TimeseriesUtils', TimeseriesUtils);

    // TimeseriesUtils.$inject = ['$filter'];

    function TimeseriesUtils() {

        var service = {
            parseTsDate: parseTsDate,
            getTsDatePattern: getTsDatePattern,
            getMaxDate: getMaxDate,
            seriesToChartSeries: seriesToChartSeries,
            convertDateToTsDate: convertDateToTsDate
        };

        return service;

        /*computes date number from the IRIS date string*/
        function parseTsDate(dateString, frequency) {
            var pattern;
            switch (frequency) {
                case 1:
                    pattern = /(\d{2}|\d{4})y?.*/i;
                    break;
                case 4:
                    pattern = /(\d{2}|\d{4})q(\d{1,2})/i;
                    break;
                case 12:
                    pattern = /(\d{2}|\d{4})m(\d{1,2})/i;
                    break;
            }
            var y = parseInt(dateString.replace(pattern, "$1"));
            var p = (frequency == 1) ? 1 : parseInt(dateString.replace(pattern, "$2"));
            var m = (frequency == 1) ? 0 : (frequency == 4) ? p * 3 - 3 : p - 1;

            if (y >= 70 && y < 100) {
                y += 1900;
            }
            if (y < 70) {
                y += 2000;
            }

            return new Date(y, m, 1)
        }

        /*converts Date object to the IRIS date string formatted according to the dateFormat*/
        function convertDateToTsDate(dateObj, frequency, dateFormat) {
            if (typeof(dateObj) == 'string')
                dateObj = new Date(dateObj);
            var y = dateObj.getFullYear();
            var m = dateObj.getMonth();
            var p = ((frequency == 1) ? 1 : (frequency == 4) ? Math.ceil((m + 1) / 3) : m + 1).toString();
            var freqLetter = (frequency == 1) ? 'y' : (frequency == 4) ? 'q' : 'm';
            var dateString = dateFormat;
            var hasFreqLetter = (dateString.search(/f/i) !== -1);
            var hasMonthName = (dateString.search(/mmm/i) !== -1);
            dateString = dateString.replace(/yyyy/i, y.toString());
            dateString = dateString.replace(/yy/i, y.toString().slice(-2));
            dateString = dateString.replace(/f/, freqLetter);
            dateString = dateString.replace(/F/, freqLetter.toUpperCase());
            dateString = dateString.replace(/pp/i, p.length == 1 ? '0' + p : p);
            dateString = dateString.replace(/p/i, p);
            // month names
            var monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"];
            dateString = dateString.replace(/Mmmm/, monthNames[m]);
            dateString = dateString.replace(/mmmm/, monthNames[m].toLowerCase());
            dateString = dateString.replace(/MMMM/, monthNames[m].toUpperCase());
            dateString = dateString.replace(/Mmm/, monthNames[m].slice(0, 3));
            dateString = dateString.replace(/mmm/, monthNames[m].toLowerCase().slice(0, 3));
            dateString = dateString.replace(/MMM/, monthNames[m].toUpperCase().slice(0, 3));
            dateString = dateString.replace(/mm/i, (m <= 8) ? '0' + (m + 1).toString() : (m + 1).toString());
            if ((frequency !== 12 || !hasFreqLetter) && !hasMonthName) {
                dateString = dateString.replace(/m/i, (m + 1).toString());
            }

            return dateString;
        }

        /*computes date string pattern from the IRIS date string*/
        function getTsDatePattern(dateString, frequency) {
            var pattern = /(\d{2}|\d{4})m(\d{1,2})/i;
            var y = "Y".repeat(dateString.replace(pattern, "$1").length);
            var m = "P".repeat(dateString.replace(pattern, "$2").length);

            return y + "F" + m;
        }

        /*computes maximum date from the current period string
         and number of months from now*/
        function getMaxDate(currentPeriod, monthsFromNow) {
            //convert current date string to js Date()
            var currentDate = parseTsDate(currentPeriod);
            //maximum year is the current year plus number
            // of full years in the number of months after
            // adding figure from the config
            var maxYear = currentDate.getFullYear()
                + Math.floor((monthsFromNow + (currentDate.getMonth() + 1)) / 12);
            //maximum month is the reminder after subtracting
            // full years from the sum of the current month
            // and the figure from config
            var maxMonth = (currentDate.getMonth() + monthsFromNow) % 11;
            return new Date(maxYear, maxMonth, currentDate.getDate());
        }

        /*cuts time series to fir the [minDate,maxDate] interval and converts it to
         the array of objects with two fields: 'time' and 'value' */
        function seriesToChartSeries(series, minDate, maxDate) {
            var chartSeries = [];

            angular.forEach(series, function (value, key) {
                var time = parseTsDate(key);
                if (time >= minDate && time <= maxDate) {
                    chartSeries.push({
                        time: time,
                        value: value
                    });
                }
            });

            return chartSeries;
        }
    }

})();
