(function () {
    'use strict';
    angular
        .module('portalApp')
        .factory('DataExport', DataExport);

    DataExport.$inject = ['$resource'];

    function DataExport($resource) {
        var resourceUrl = 'api/dataExport';
        return function (exportType) {
            if (exportType === 'excel') {
                return $resource(resourceUrl + '/excel/', {}, {
                    'query': {
                        method: 'GET',
                        responseType: "arraybuffer",
                        transformResponse: function (data, headers) {
                            var response = {};
                            response.data = data;
                            response.headers = headers();
                            return response;
                        }
                    }
                });
            }
        }
    }
})();
