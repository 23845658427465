(function () {
    'use strict';

    angular
        .module('portalApp')
        .config(translationConfig);

    translationConfig.$inject = ['$translateProvider', 'tmhDynamicLocaleProvider'];

    function translationConfig($translateProvider, tmhDynamicLocaleProvider) {
        // Initialize angular-translate
        $translateProvider.useLoader('$translatePartialLoader', {
            urlTemplate: 'i18n/{lang}/{part}.json'
        });

        $translateProvider.preferredLanguage('en');
        $translateProvider.useStorage('translationStorageProvider');
        $translateProvider.useSanitizeValueStrategy('escaped');
        $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

        tmhDynamicLocaleProvider.localeLocationPattern('i18n/angular-locale_{{locale}}.js');
        tmhDynamicLocaleProvider.useCookieStorage();
        tmhDynamicLocaleProvider.storageKey('NG_TRANSLATE_LANG_KEY');

        // add missing case for the CS locale months
        moment.locale('cs', {
            months: function (m, format) {
                var months = {
                        'nominative': 'leden_únor_březen_duben_květen_červen_červenec_srpen_září_říjen_listopad_prosinec'.split('_'),
                        'genitive': 'ledna_února_března_dubna_května_června_července_srpna_září_října_listopadu_prosince'.split('_')
                    },
                    nounCase = (/D[oD\.]?(\[[^\[\]]*\]|\s+)+MMMM?/).test(format) ?
                        'genitive' :
                        'nominative';
                return months[nounCase][m.month()];
            }
        });
    }
})();
