(function () {
    'use strict';
    angular
        .module('portalApp')
        .controller('FpasController', FpasController);

    FpasController.$inject = ['$filter', '$state', 'subscribedCountryList', 'latestFpasRound', 'latestFullFpasRound',
        'fpasTimeseriesData', 'fpasResources', 'DataExport', 'DataUtils', 'Resource', '$window', 'latestYcmRound'];

    function FpasController($filter, $state, subscribedCountryList, latestFpasRound, latestFullFpasRound,
                            fpasTimeseriesData, fpasResources, DataExport, DataUtils, Resource, $window, latestYcmRound) {
        var vm = this;
        vm.downloadResource = downloadResource;
        vm.getFpasDatabase = getFpasDatabase;
        vm.onDownload = onDownload;
        vm.showFcastNote = false;
        vm.showInterimNote = [];
        vm.excelName = "";
        vm.excelExt = "xlsx";
        vm.country = subscribedCountryList.find(function (c) {
            return (c.id == $state.params.countryId);
        });
        vm.latestFpasRound = latestFpasRound;
        vm.latestFullFpasRound = latestFullFpasRound;
        vm.latestYcmRound = latestYcmRound;
        vm.isFullFpas = true;
        // if round is not found (doesn't exist or not subscribed) => stop here
        if (latestFpasRound !== null) {
            // check whether the round is full FPAS or not
            vm.isFullFpas = (vm.latestFullFpasRound !== null && vm.latestFullFpasRound.id === vm.latestFpasRound.id);
            vm.latestFullFpasRound = vm.latestFullFpasRound || vm.latestFpasRound;
            // file name for the exported data
            vm.excelName = vm.country.name + " -- Forecast Database (" + $filter('date')(vm.latestFpasRound.created, 'dd-MMM-yyyy') + ").xlsx";

            // pre-process the resources (add descriptions, icon types, ordering etc.)
            vm.fpasResources = fpasResources.map(function (res) {
                if (res.filename.indexOf("presentation") !== -1) {
                    res.ix = 3;
                    res.desc = "Presentation";
                    res.ext = "pdf";
                    res.icon = "fa fa-file-pdf-o fa-fw";
                    res.isExcelExport = false;
                    res.alwaysFull = true;
                    res.hasInfoNote = false;
                }
                else if (res.filename.indexOf("screening") !== -1) {
                    res.ix = 2;
                    res.desc = "Data Screening Report";
                    res.ext = "pdf";
                    res.icon = "fa fa-file-pdf-o fa-fw";
                    res.isExcelExport = false;
                    res.alwaysFull = true;
                    res.hasInfoNote = !vm.isFullFpas;
                }
                else if (res.filename.indexOf("short") !== -1) {
                    res.ix = 0;
                    res.desc = "Forecast Summary";
                    res.ext = "pdf";
                    res.icon = "fa fa-file-pdf-o fa-fw";
                    res.isExcelExport = false;
                    res.alwaysFull = false;
                    res.hasInfoNote = true;
                }
                else {
                    res.ix = 4;
                    res.desc = "Unknown file";
                    res.ext = "";
                    res.icon = "fa fa-file-o fa-fw";
                    res.isExcelExport = false;
                    res.alwaysFull = false;
                    res.hasInfoNote = false;
                }
                return res;
            });
            // add an extra resource for the data export
            vm.fpasResources.push({
                ix: 1,
                desc: "Forecast Database",
                ext: vm.excelExt,
                icon: "fa fa-file-excel-o fa-fw",
                isExcelExport: true,
                alwaysFull: false,
                hasInfoNote: true
            });
        }

        /**
         * Triggers downloading -- either proper resource downloading from SVN or a data export
         * @param res      resource object
         * @param isExport true when data Excel file is to be generated from the database content
         */
        function downloadResource(res, isExport) {
            if (isExport) {
                getFpasDatabase();
            }
            else {
                vm.onDownload(res.desc + ': ' + vm.country.name);
                Resource
                    .download({id: res.id, download: true})
                    .$promise
                    .then(function (response) {
                        var fileNameIndex = response.headers["content-disposition"].indexOf("filename=");
                        var fileName = vm.country.name + " -- " + res.desc + " ("
                            + $filter('date')((res.alwaysFull) ? vm.latestFullFpasRound.created : vm.latestFpasRound.created, 'dd-MMM-yyyy')
                            + ")" + ((res.ext === "") ? "" : ("." + res.ext));
                        var fileNameInRepo = "unknown";
                        if (fileNameIndex > -1) {
                            fileNameInRepo = response.headers["content-disposition"].substr(fileNameIndex + "filename=".length);
                        }
                        DataUtils.saveFile(response.headers["content-type"],
                            response.data, fileName);
                    });
            }

        }

        /**
         * Inform google analytics about file download started
         * @param fileLabel
         */
        function onDownload(fileLabel) {
            $window.dataLayer = $window.dataLayer || [];
            fileLabel = fileLabel || "Unknown file";
            $window.dataLayer.push({
                'event': 'download',
                'category': 'document',
                'action': 'download',
                'label': fileLabel
            });
        }

        /**
         * Get the Excel file with forecast results
         */
        function getFpasDatabase() {
            vm.onDownload(vm.excelName);
            DataExport('excel')
                .query({
                    roundId: vm.latestFpasRound.id,
                    format: vm.excelExt,
                    exportAll: false,
                    seriesScale: "delog"
                })
                .$promise
                .then(function (response) {
                    DataUtils.saveFile(response.headers["content-type"],
                        response.data, vm.excelName);
                });
        }
    }
})();



