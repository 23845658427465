(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('ApplicationFeatureEntityMapDetailController', ApplicationFeatureEntityMapDetailController);

    ApplicationFeatureEntityMapDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ApplicationFeatureEntityMap'];

    function ApplicationFeatureEntityMapDetailController($scope, $rootScope, $stateParams, entity, ApplicationFeatureEntityMap) {
        var vm = this;

        vm.applicationFeatureEntityMap = entity;

        var unsubscribe = $rootScope.$on('portalApp:applicationUpdate', function(event, result) {
            vm.applicationFeatureEntityMap = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
