(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('ModelEquationDetailController', ModelEquationDetailController);

    ModelEquationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ModelEquation', 'Model'];

    function ModelEquationDetailController($scope, $rootScope, $stateParams, entity, ModelEquation, Model) {
        var vm = this;

        vm.modelEquation = entity;

        var unsubscribe = $rootScope.$on('portalApp:modelEquationUpdate', function(event, result) {
            vm.modelEquation = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
