(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('RevisionDetailController', RevisionDetailController);

    RevisionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Revision'];

    function RevisionDetailController($scope, $rootScope, $stateParams, entity, Revision) {
        var vm = this;

        vm.revision = entity;

        var unsubscribe = $rootScope.$on('portalApp:revisionUpdate', function(event, result) {
            vm.revision = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
