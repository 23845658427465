(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('DataProtectionPolicyController', DataProtectionPolicyController);

    DataProtectionPolicyController.$inject = ['$resource', '$translate'];

    function DataProtectionPolicyController ($resource, $translate) {
        var vm = this;

        vm.date_accepted = null;

        var UserAcceptance = $resource('api/user_acceptance?code=GDPR-text');

        var ua = UserAcceptance.query();

        ua.$promise.then(function () {
            vm.date_accepted = ua[0].accepted;
        })

    }
})();
