(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('SmootherMultiplierDetailController', SmootherMultiplierDetailController);

    SmootherMultiplierDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'SmootherMultiplier', 'ModelSymbol', 'Timeseries'];

    function SmootherMultiplierDetailController($scope, $rootScope, $stateParams, entity, SmootherMultiplier, ModelSymbol, Timeseries) {
        var vm = this;

        vm.smootherMultiplier = entity;

        var unsubscribe = $rootScope.$on('portalApp:smootherMultiplierUpdate', function(event, result) {
            vm.smootherMultiplier = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
