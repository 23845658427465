(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('ModelSymbolDetailController', ModelSymbolDetailController);

    ModelSymbolDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ModelSymbol', 'Model', 'SbNtfVariable', 'SbNtfExcludedShock', 'SmootherMultiplier'];

    function ModelSymbolDetailController($scope, $rootScope, $stateParams, entity, ModelSymbol, Model, SbNtfVariable, SbNtfExcludedShock, SmootherMultiplier) {
        var vm = this;

        vm.modelSymbol = entity;

        var unsubscribe = $rootScope.$on('portalApp:modelSymbolUpdate', function(event, result) {
            vm.modelSymbol = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
