(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('ApplicationFeatureUserGroupMapController', ApplicationFeatureUserGroupMapController);

    ApplicationFeatureUserGroupMapController.$inject = ['$scope', '$state', 'ApplicationFeatureUserGroupMap', 'ApplicationFeatureUserGroupMapSearch'];

    function ApplicationFeatureUserGroupMapController ($scope, $state, ApplicationFeatureUserGroupMap, ApplicationFeatureUserGroupMapSearch) {
        var vm = this;

        vm.applications = [];
        vm.search = search;

        loadAll();

        function loadAll() {
            ApplicationFeatureUserGroupMap.query(function(result) {
                vm.applications = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            ApplicationFeatureUserGroupMapSearch.query({query: vm.searchQuery}, function(result) {
                vm.applications = result;
            });
        }    }
})();
