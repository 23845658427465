(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('ApplicationFeatureUserGroupMapDetailController', ApplicationFeatureUserGroupMapDetailController);

    ApplicationFeatureUserGroupMapDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ApplicationFeatureUserGroupMap'];

    function ApplicationFeatureUserGroupMapDetailController($scope, $rootScope, $stateParams, entity, ApplicationFeatureUserGroupMap) {
        var vm = this;

        vm.applicationFeatureUserGroupMap = entity;

        var unsubscribe = $rootScope.$on('portalApp:applicationUpdate', function(event, result) {
            vm.applicationFeatureUserGroupMap = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
