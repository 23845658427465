(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('ModelSymbolDialogController', ModelSymbolDialogController);

    ModelSymbolDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ModelSymbol', 'Model', 'SbNtfVariable', 'SbNtfExcludedShock', 'SmootherMultiplier'];

    function ModelSymbolDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ModelSymbol, Model, SbNtfVariable, SbNtfExcludedShock, SmootherMultiplier) {
        var vm = this;

        vm.modelSymbol = entity;
        vm.clear = clear;
        vm.save = save;
        vm.models = Model.query();
        vm.sbntfvariables = SbNtfVariable.query();
        vm.sbntfexcludedshocks = SbNtfExcludedShock.query();
        vm.smoothermultipliers = SmootherMultiplier.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.modelSymbol.id !== null) {
                ModelSymbol.update(vm.modelSymbol, onSaveSuccess, onSaveError);
            } else {
                ModelSymbol.save(vm.modelSymbol, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('portalApp:modelSymbolUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
