(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('SmootherMultiplierController', SmootherMultiplierController);

    SmootherMultiplierController.$inject = ['$scope', '$state', 'SmootherMultiplier', 'SmootherMultiplierSearch'];

    function SmootherMultiplierController ($scope, $state, SmootherMultiplier, SmootherMultiplierSearch) {
        var vm = this;

        vm.smootherMultipliers = [];
        vm.search = search;

        loadAll();

        function loadAll() {
            SmootherMultiplier.query(function(result) {
                vm.smootherMultipliers = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            SmootherMultiplierSearch.query({query: vm.searchQuery}, function(result) {
                vm.smootherMultipliers = result;
            });
        }    }
})();
