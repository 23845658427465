(function() {
    'use strict';

    angular
        .module('portalApp')
        .controller('CurrencyJournalDetailController', CurrencyJournalDetailController);

    CurrencyJournalDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'CurrencyJournal', 'Currency', 'Country'];

    function CurrencyJournalDetailController($scope, $rootScope, $stateParams, entity, CurrencyJournal, Currency, Country) {
        var vm = this;

        vm.currencyJournal = entity;

        var unsubscribe = $rootScope.$on('portalApp:currencyJournalUpdate', function(event, result) {
            vm.currencyJournal = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
